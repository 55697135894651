import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    CreateLink,
    diffSourcePlugin,
    DiffSourceToggleWrapper,
    headingsPlugin,
    InsertTable,
    linkDialogPlugin,
    listsPlugin,
    ListsToggle,
    MDXEditor,
    quotePlugin,
    Separator,
    setMarkdown$,
    tablePlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    UndoRedo
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { useEffect, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { Each } from "../common/Each"
import Button from "./Button"
import styles from "./CourseProgramEditor.module.css"
import TextInput from "./TextInput"
import { CourseType } from "../common/constants"
const CourseProgramEditor = ({ program, onChange = () => { }, courseType }) => {

    const [courseProgram, setCourseProgram] = useState(program)

    useEffect(() => {
        onChange(courseProgram)
    }, [courseProgram])


    return (
        <div className={styles.container}>
            <ReactSortable list={courseProgram} setList={setCourseProgram} dragClass={styles.dragClass} dragoverBubble={true} ghostClass={styles.ghostClass} chosenClass={styles.chosenClass} handle=".handle" draggable=".sortable-program">
                <Each of={courseProgram} render={(item, index) => {
                    return (
                        <div className={`${styles.item} ${item._selected ? styles.open : ''} sortable-program`}>
                            <div className={`${styles.header} ${item._selected ? styles.open : ''} handle`} onClick={() => {
                                let newCourseProgram = courseProgram.map((itm, idx) => {
                                    if (idx === index) {
                                        itm._selected = !itm._selected
                                    }
                                    return itm
                                })
                                setCourseProgram([...newCourseProgram])
                            }}>
                                <div className={styles.title}>{item.name}</div>
                                <ChevronIcon className={`${styles.chevron} ${item._selected ? styles.open : ''}`} />
                            </div>
                            <div className={`${styles.content} ${item._selected ? styles.open : ''}`} >
                                <TextInput
                                    style={{ fontSize: '.75rem' }}
                                    type="text"
                                    placeholder={"Titolo"}
                                    value={item.name}
                                    onKeyUp={(value) => {
                                        let newCourseProgram = courseProgram.map((itm, idx) => {
                                            if (idx === index) {
                                                itm.name = value
                                            }
                                            return itm
                                        })
                                        setCourseProgram([...newCourseProgram])
                                    }} />
                                <MDXEditor
                                    className={styles.description}
                                    markdown={item.description}
                                    onChange={(md) => {
                                        let newCourseProgram = courseProgram.map((itm, idx) => {
                                            if (idx === index) {
                                                itm.description = md
                                            }
                                            return itm
                                        })
                                        setCourseProgram([...newCourseProgram])
                                    }}
                                    plugins={[
                                        headingsPlugin(),
                                        listsPlugin(),
                                        quotePlugin(),
                                        thematicBreakPlugin(),
                                        linkDialogPlugin(),
                                        diffSourcePlugin(),
                                        tablePlugin(),
                                        toolbarPlugin({
                                            toolbarContents: () => (
                                                <>
                                                    {' '}
                                                    <UndoRedo />
                                                    <Separator />
                                                    <BoldItalicUnderlineToggles />
                                                    <Separator />
                                                    <ListsToggle />
                                                    <CreateLink />
                                                    <InsertTable />
                                                    <Separator />
                                                    <BlockTypeSelect />
                                                    <DiffSourceToggleWrapper options={["rich-text", "source"]} />
                                                </>
                                            )
                                        })
                                    ]}
                                />
                                {/* <textarea className={styles.description} value={item.description} placeholder="Descrizione" onChange={(e) => {
                                    let value = e.target.value
                                    let newCourseProgram = courseProgram.map((itm, idx) => {
                                        if (idx === index) {
                                            itm.description = value
                                        }
                                        return itm
                                    })
                                    setCourseProgram([...newCourseProgram])
                                }} /> */}
                                {courseType === CourseType.Professional &&
                                    <TextInput type="number" placeholder={"Durata(H)"} value={item.duration} onKeyUp={(value) => {
                                        let newCourseProgram = courseProgram.map((itm, idx) => {
                                            if (idx === index) {
                                                itm.duration = value
                                            }
                                            return itm
                                        })
                                        setCourseProgram([...newCourseProgram])
                                    }} />
                                }
                                <Button onClick={() => {
                                    let newCourseProgram = courseProgram.filter((itm, idx) => {
                                        return (idx !== index)
                                    })
                                    setCourseProgram([...newCourseProgram])

                                }} appearance="text" accentColor="var(--sf-red)" style={{ width: 'fit-content' }}>Elimina</Button>
                            </div>
                        </div>
                    )
                }} />
            </ReactSortable>
            <Button fullWidth accentColor="var(--tertiary)"
                style={{ borderRadius: '0 0 1rem 1rem' }}
                onClick={() => {
                    let newItem = { icon: '', title: 'Nuovo', description: '' }
                    setCourseProgram((prev) => {
                        return [...prev, newItem]
                    })
                }}>
                <AddIcon className={styles.addIcon} />
                AGGIUNGI
            </Button>
        </div>
    )
}

export default CourseProgramEditor
