import { useCallback, useEffect, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { v4 } from "uuid"
import api from "../api"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as EmptyIllustration } from "../assets/images/illustrations/il-empty.svg"
import { ReactComponent as InsertDocumentIllustration } from "../assets/images/illustrations/il-insert-document.svg"
import { Each } from "../common/Each"
import Button from "../components/Button"
import ExerciseCard from "../components/cards/ExerciseCard"
import Dialog from "../components/dialogs/Dialog"
import DropdownSelection from "../components/DropdownSelection"
import EmptyContent from "../components/EmptyContent"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Loader from "../components/Loader"
import MaterialButton from "../components/MaterialButton"
import Pagination from "../components/Pagination"
import TextInput from "../components/TextInput"
import typo from "../typography.module.css"
import styles from "./Exercises.module.css"
import Card from "../components/cards/Card"
import TextareaAutosize from 'react-textarea-autosize'

const Exercises = ({ }) => {

    const { t } = useTranslation()

    const [open, setOpen] = useState(false)
    const [exercises, setExercises] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(true)
    const [page, setPage] = useState(0)
    const [per, setPer] = useState(10)
    const [count, setCount] = useState(null)
    const [showPagination, setShowPagination] = useState(false)

    const [openDialog, setOpenDialog] = useState(false)
    const [courses, setCourses] = useState([])
    const [editions, setEditions] = useState([])
    const [modules, setModules] = useState([])
    const [lessons, setLessons] = useState([])

    const [course, setCourse] = useState(null)
    const [edition, setEdition] = useState(null)
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [expiresAt, setExpiresAt] = useState(null)
    const [module, setModule] = useState(null)
    const [lesson, setLesson] = useState(null)
    const [files, setFiles] = useState([])

    const [waitingAdd, setWaitingAdd] = useState(false)

    const dropzoneRef = useRef(null)

    useEffect(() => {
        getExercises();
    }, [page]);

    useEffect(() => {
        getCourses()
    }, [])

    useEffect(() => {
        if (course) {
            getEditions()
        } else {
            setEditions([])
            setModules([])
            setLessons([])
        }
    }, [course])


    useEffect(() => {
        if (edition) {
            setModules(edition.modules.map(m => ({ ...m, label: m.name })))
        } else {
            setModules([])
            setLessons([])
        }
    }, [edition])

    useEffect(() => {
        if (module) {
            setLessons(module.lessons.map(l => ({ ...l, label: l.name })))
        } else {
            setLessons([])
        }
    }, [module])

    useEffect(() => {
        if (!openDialog) {
            onDialogClose()
        }
    }, [openDialog])

    const getCourses = async () => {
        try {
            const courses = await api.get("/admin/courses");
            setCourses(courses.map(c => ({ ...c, label: c.name })))
        } catch (e) {
            console.error(e)
        }
    }

    const getEditions = async () => {
        try {
            let editions = await api.get(`/admin/courses/${course.id}/editions`);
            editions = editions.filter(e => e.modules && e.modules.length > 0)
            setEditions(editions.map(e => ({ ...e, label: e.name })))
        } catch (e) {
            console.error(e)
        }
    }

    const getExercises = useCallback(async (q = null) => {
        setLoading(true);
        try {
            const data = q ?
                await api.get(`/admin/exercises?q=${q}`) :
                await api.get(`/admin/exercises?page=${page}&per=${per}`);
            setExercises(data.exercises);
            setCount(data.count)
            setShowPagination(data.count > per)
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
        if (loadingPage) {
            setLoadingPage(false)
        }
    }, [])

    const onSearch = (searchText) => {
        if (searchText) {
            getExercises(searchText)
        } else {
            setPage(0)
        }
    }


    const addExercise = async () => {
        setWaitingAdd(true)
        const form = new FormData()
        form.append("name", name)
        form.append("module_id", module.id)

        if (description) {
            form.append("description", description)
        }
        if (lesson !== null) {
            form.append("lesson_id", lesson.id)
        }
        if (expiresAt) {
            form.append("expires_at", new Date(expiresAt).toISOString())
        }


        for (let i = 0; i < files.length; i++) {
            form.append(`files`, files[i])
        }
        try {
            const res = await api.post("/admin/exercises", form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            setOpenDialog(false)
        } catch (e) {
            console.error(e)
        }
        setWaitingAdd(false)
    }


    const deleteExercise = async (id) => {
        try {
            await api.delete(`/admin/exercises/${id}`)
            getExercises()
        } catch (e) {
            console.error(e)
        }
    }

    const onDialogClose = () => {
        setCourse(null)
        setEdition(null)
        setName("")
        setDescription("")
        setExpiresAt(null)
        setModule(null)
        setLesson(null)
        setFiles([])
    }

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>{t("exercises.title")}</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.content}>
                            <div className={styles.header}>
                                <div className={typo.title}>{t("exercises.title")}</div>
                                <div className={styles.add}>
                                    <Button
                                        style={{
                                            padding: "1rem 2rem",
                                        }}
                                        onClick={() => setOpenDialog(true)}
                                    >
                                        {t("exercises.add").toUpperCase()}
                                        <AddIcon />
                                    </Button>
                                </div>
                            </div>
                            <TextInput
                                onKeyUp={onSearch}
                                type="search"
                                placeholder={"Ricerca"}
                                style={{
                                    backgroundColor: "var(--background-secondary-color)",
                                }} />
                            <div className={styles.exercises}>
                                {
                                    loading === false && exercises.length > 0 &&
                                    <Each
                                        of={exercises}
                                        render={exercise => (
                                            <div className={styles.exercise}>
                                                <ExerciseCard exercise={exercise} onDelete={() => deleteExercise(exercise.id)} />
                                            </div>
                                        )}
                                    />
                                }

                                {
                                    loading === true &&
                                    <Each
                                        of={[0, 1, 2, 3, 4]}
                                        render={exercise => (
                                            <div className={styles.exercise}>
                                                <ExerciseCard loading />
                                            </div>
                                        )}
                                    />
                                }

                                {
                                    loading === false && exercises.length === 0 &&
                                    <EmptyContent
                                        Illustration={EmptyIllustration}
                                        title={"Nessun esercizio ancora creato"}
                                        message={
                                            <div style={{ display: "flex", alignItems: "center", }}>
                                                <Button
                                                    appearance="text"
                                                    onClick={() => setOpen(true)}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: ".2rem"
                                                    }}
                                                >
                                                    <AddIcon />
                                                    Aggiungi
                                                </Button>
                                            </div>
                                        }
                                    />
                                }
                            </div>
                            {
                                showPagination &&
                                <Pagination total={count} per={per} page={page} onChange={p => setPage(p)} loading={loadingPage} />
                            }
                        </div>
                    </div>

                    <Dialog
                        open={openDialog}
                        title={"Crea esercizio"}
                        style={{ maxWidth: "512px" }}
                        onClose={() => setOpenDialog(false)}
                        action={
                            <Button onClick={addExercise}>
                                {t("exercises.add").toUpperCase()}
                            </Button>
                        }
                    >
                        <div className={styles.dialog}>
                            <TextInput
                                type="text"
                                placeholder={t("exercises.name")}
                                onKeyUp={(s) => {
                                    setName(s)
                                }}
                                value={name}
                            />

                            <Card hover style={{ padding: ".5rem 1rem", backgroundColor: "transparent" }}>
                                <div className={styles.textAreaContainer}>
                                    <TextareaAutosize
                                        placeholder={t("exercises.description")}
                                        value={description}
                                        minRows={3} maxRows={10}
                                        type="text"
                                        className={styles.textArea}
                                        onChange={(e) => {
                                            const { value } = e.target
                                            setDescription(value)
                                        }} />
                                </div>
                            </Card>
                            <DropdownSelection
                                disabled={courses.length === 0}
                                placeholder={"Corso"}
                                defaultOptionIndex={course}
                                options={courses}
                                appereance="transparent"
                                onSelect={(index) => {
                                    setCourse(courses[index])
                                }} />

                            <DropdownSelection
                                disabled={editions.length === 0}
                                placeholder={"Edizione"}
                                defaultOptionIndex={edition}
                                options={editions}
                                appereance="transparent"
                                onSelect={(id) => {
                                    setEdition(editions.find(e => e.id === id))
                                    setModule(null)
                                    setLesson(null)
                                }} />

                            <DropdownSelection
                                disabled={modules.length === 0}
                                placeholder={"Modulo"}
                                defaultOptionIndex={module}
                                options={modules}
                                appereance="transparent"
                                onSelect={(id) => {
                                    setModule(modules.find(e => e.id === id))
                                    setLesson(null)
                                }} />

                            <DropdownSelection
                                disabled={lessons.length === 0}
                                placeholder={"Lezione"}
                                defaultOptionIndex={lesson}
                                options={lessons}
                                appereance="transparent"
                                onSelect={(id) => setLesson(lessons.find(e => e.id === id))} />

                            <TextInput
                                type="date"
                                value={expiresAt}
                                placeholder={t("exercises.expiresAt")}
                                onKeyUp={(d) => setExpiresAt(d)}
                            />

                            <div className={styles.dropzoneContainer}>
                                <div className={styles.subtitle} >
                                    Materiali
                                    {files.length > 0 &&
                                        <AddIcon style={{ cursor: "pointer", color: "var(--tertiary)" }} onClick={() => {
                                            if (dropzoneRef && dropzoneRef.current) {
                                                dropzoneRef.current.click();
                                            }
                                        }} />
                                    }
                                </div>
                                {
                                    files.length > 0 && waitingAdd === false &&
                                    <div className={styles.filesContainer}>
                                        <div className={styles.files}>
                                            <Each
                                                of={files}
                                                render={(file) => (
                                                    <div className={styles.file}>
                                                        <MaterialButton
                                                            material={file}
                                                            onClick={() => {
                                                                setFiles((prevFiles) => {
                                                                    const idx = prevFiles.findIndex(f => f.id === file.id)
                                                                    if (idx > -1) prevFiles.splice(idx, 1)
                                                                    return [...prevFiles]
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                }
                                <div style={{
                                    display: files.length === 0 && waitingAdd === false ? "flex" : "none",
                                }}>
                                    <Dropzone
                                        onDrop={(newFiles) => {
                                            newFiles.forEach(f => f.id = v4())
                                            setFiles([...files, ...newFiles])
                                        }}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                                                <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                                                    <input {...getInputProps()} />
                                                    <div className={styles.dropzone}>
                                                        <InsertDocumentIllustration />
                                                        <div className={styles.dropzoneLabel}>
                                                            {t("materials.dragAndDrop")}
                                                        </div>
                                                        <Button
                                                            style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                            additionalClass={styles.blueButton}
                                                            onClick={() => dropzoneRef.current?.click()}
                                                        >
                                                            {t("materials.selectFile").toUpperCase()}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                {
                                    waitingAdd === true &&
                                    <div className={styles.loader}>
                                        <Loader />
                                    </div>
                                }
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>

        </HeaderFooterLayout>
    )
}


export default Exercises
