import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as CartIcon } from "../../assets/images/icons/ic-cart.svg"
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg"
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg"
import { ReactComponent as CopIcon } from "../../assets/images/icons/ic-cop.svg"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import { AccountStatus, AdminRole } from "../../common/constants"
import Skeleton from "../Skeleton"
import styles from "./AdminCard.module.css"

const AdminCard = ({ admin, loading = false, onClick = () => { }, style, options = { status: true, chevron: true } }) => {
    const { t } = useTranslation()

    const [picture, setPicture] = useState(admin?.picture ?? UserPlaceholder)

    useEffect(() => {
        setPicture(admin?.picture ?? UserPlaceholder)
    }, [admin])

    return (
        <>
            {
                loading &&
                <div className={`${styles.container}`} onClick={onClick}>
                    <div className={styles.info}>
                        <div className={styles.left}>
                            <Skeleton type="circle" width="72px" height="72px" />
                            <div className={styles.infoInner}>
                                <Skeleton type="rect" width="72px" height="20px" borderRadius={"8px"} />
                                <Skeleton type="rect" width="144px" height="14px" borderRadius={"8px"} />
                            </div>
                        </div>
                        <ChevronIcon className={styles.chevron} />
                    </div>
                </div>
            }
            {
                !loading &&
                <div className={`${styles.container}`} onClick={onClick}>
                    <div className={styles.info}>
                        <div className={styles.left}>
                            <img src={picture} className={styles.avatar} style={{ borderColor: admin.color }} alt="admin" onError={() => setPicture(UserPlaceholder)} />
                            <div className={styles.infoInner}>
                                <div className={styles.name}>{admin.name} {admin.surname}</div>
                                <div className={styles.email}>{admin.email}</div>
                                <div className={styles.role}>
                                    {admin.role === AdminRole.Main && <CopIcon />}
                                    {admin.role === AdminRole.Sales && <CartIcon />}
                                    {t(`${admin.role}`)}
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            {options.status &&
                                <>
                                    {
                                        admin.status === AccountStatus.Active &&
                                        <div className={`${styles.status} ${styles.active}`}>ATTIVO</div>
                                    }
                                    {
                                        admin.status === AccountStatus.Suspended &&
                                        <div className={`${styles.status} ${styles.suspended}`}>SOSPESO</div>
                                    }
                                    {options.chevron &&
                                        <ChevronIcon className={styles.chevron} />
                                    }
                                </>
                            }
                            {options.selected &&
                                <div className={styles.checkIcon}>
                                    <CheckIcon />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default AdminCard
