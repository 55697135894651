import '@mdxeditor/editor/style.css';
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg";
import { Each } from "../common/Each";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import Button from "../components/Button";
import VideoCard from "../components/cards/VideoCard";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import TextInput from "../components/TextInput";
import typo from "../typography.module.css";
import styles from "./Videos.module.css";


const Videos = ({ }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [videos, setVideos] = useState([])
  const [page, setPage] = useState(0)
  const [per, setPer] = useState(10)
  const [loading, setLoading] = useState(false)
  const [showPagination, setShowPagination] = useState(true)

  useEffect(() => {
    getVideos()
  }, []);

  const getVideos = async (q = null) => {
    setLoading(true)
    try {
      const data = q ?
        await api.get(`/admin/videos?q=${q}`) :
        await api.get(`/admin/videos?page=${page}&per=${per}`)
      setVideos(data.videos)
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  const onSearch = async (searchText) => {
    if (loading)
      return

    if (searchText.length > 0) {
      await getVideos(searchText.toLowerCase())
      setShowPagination(false)
    } else {
      await getVideos()
      setShowPagination(true)
    }
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Gestione video</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={styles.header}>
              <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                Video
              </div>
              <Button style={{ padding: ".75rem 1rem" }} onClick={() => navigate(`/videos/create/editor`)}>
                AGGIUNGI
                <AddIcon />
              </Button>
            </div>

            <TextInput
              onKeyUp={onSearch}
              type="search"
              placeholder={t("search")} />

            <div className={styles.videos}>
              <Each
                of={videos}
                render={(video) => (
                  <VideoCard video={video} onEdit={() => navigate(`/videos/${video.id}/editor`)} />
                )}
              />

            </div>
          </div>
        </div>
      </div>
    </HeaderFooterLayout>
  );
};

export default Videos;
