import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { BookingStatus } from "../../common/constants";
import { Each } from "../../common/Each";
import MainContext from "../../common/MainContext";
import BookingCard from "../cards/BookingCard";
import TextInput from "../TextInput";
import Pagination from "../Pagination";
import styles from "./BookingsCard.module.css";
import Card from "./Card";
import api from "../../api";

const BookingsCard = forwardRef(({ pending = false, onClaim = () => { } }, ref) => {
  const { t } = useTranslation();
  const context = useContext(MainContext)

  const [count, setCount] = useState(0)
  const [bookings, setBookings] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)
  const [page, setPage] = useState(0)
  const [per, setPer] = useState(25)
  const [showPagination, setShowPagination] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      getBookings: () => {
        if (page === 0) {
          getBookings()
        } else {
          setPage(0)
        }
      }
    };
  }, [context.user, page]);

  useEffect(() => {
    if (context.user) {
      getBookings()
    }
  }, [context.user, page])

  const onSearch = (searchText) => {
    if (loading)
      return

    if (searchText.length > 0) {
      getBookings(searchText.toLowerCase())
      setShowPagination(false)
    } else {
      getBookings()
    }
  }

  const getBookings = async (q = null) => {
    if (loading) {
      return;
    }

    setLoading(true)
    try {
      const s = pending ? `&status=${BookingStatus.Pending}` : ``
      const a = !pending ? `&admin_id=${context.user.id}` : ``

      const data = q ?
        await api.get(`/admin/bookings?q=${q}${s}${a}`) :
        await api.get(`/admin/bookings?page=${page}&per=${per}${s}${a}`)

      const { bookings, count } = data
      setBookings(bookings)
      setCount(count)
      if (count > per) {
        setShowPagination(true)
      } else {
        setShowPagination(false)
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
    if (loadingPage) {
      setLoadingPage(false)
    }
  }

  const onPendingClaim = () => {
    getBookings()
    onClaim()
  }

  const onUpdate = () => {
    getBookings()
  }

  return (
    <Card>
      <div className={styles.container}>
        <TextInput
          onKeyUp={onSearch}
          type="search"
          placeholder={t("search")} />

        <div className={styles.bookings}>
          <div className={styles.title}>{pending ? "In attesa" : "Assegnate a me"}</div>
          <Each
            of={bookings}
            render={(booking) => (
              <BookingCard booking={booking} onClaim={onPendingClaim} onUpdate={onUpdate} />
            )}
          />
        </div>
      </div>
      {
        showPagination &&
        <Pagination total={count} per={per} page={page} onChange={p => setPage(p)} loading={loadingPage} />
      }
    </Card>
  );
});

export default BookingsCard;
