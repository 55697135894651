import { useEffect, useRef, useState } from "react"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { Each } from "../common/Each"
import useClickOutside from "../common/hooks/useClickOutside"
import styles from "./Select.module.css"

const Select = ({ selected, options, placeholder, style, onSelect = () => { }, nullable = false }) => {

    const [value, setValue] = useState(selected)
    const [opts, setOpts] = useState(options)
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [top, setTop] = useState(null)
    const containerRef = useRef(null)
    const ref = useRef(null)

    const handleClickOutside = () => {
        setOptionsOpen(false)
    }

    useClickOutside(containerRef, handleClickOutside)

    useEffect(() => {
        if (!containerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setTop(containerRef.current?.clientHeight)
        });
        resizeObserver.observe(containerRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, []);

    useEffect(() => {
        setValue(selected)
    }, [selected])

    useEffect(() => {
        var opts = options
        if (nullable) {
            opts = [{ id: -1, name: 'Nessuna selezione', value: null }, ...options]
        }
        setOpts(opts)
        if (!selected) {
            setValue(opts[0])
        }
    }, [options])

    const handleOnSelect = async (value) => {
        setValue(value)
        onSelect(value)
    }


    return (
        <div
            style={style}
            className={`${styles.container} ${optionsOpen ? styles.open : ''}`}
            ref={containerRef}
            onClick={() => {
                if (options.length > 0) {
                    setOptionsOpen(!optionsOpen)
                }
            }} >
            <div className={styles.selectedLabel}>
                {value &&
                    <>
                        {value.name}
                    </>
                }
                {!value &&
                    <>
                        {placeholder}
                    </>}
            </div>
            {opts && opts.length > 0 && optionsOpen &&
                <div className={styles.options} ref={ref} style={{ top: `${top}px` }}>
                    <Each of={opts} render={(item) => {
                        return (
                            <div className={`${styles.option} ${value.id === item.id ? styles.selected : ''}`} onClick={() => {
                                handleOnSelect(item)
                            }}>
                                {item.name}
                            </div>
                        )
                    }} />
                </div>
            }

            <ChevronIcon className={`${styles.chevron} ${optionsOpen ? styles.open : ''}`} />

        </div>
    )

}

export default Select
