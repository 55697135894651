import { cloneElement, useMemo } from 'react';
import { Tooltip } from 'react-tooltip'
import { v4 } from 'uuid';

const TooltipWrapper = ({ children, text, className = "", style = {} }) => {

  const id = useMemo(() => v4(), [])

  return (
    <>
      {cloneElement(children, { "data-tooltip-id": id, "data-tooltip-content": text })}
      {
        text &&
        <Tooltip id={id}
          multiline={true}
          style={{
            whiteSpace: "normal",
            maxWidth: "512px",
            zIndex: 9999,
            borderRadius: "16px",
            color: "#000",
            background: '#fff',
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className={className} style={{
            ...style,
          }}>{text}</div>
        </Tooltip>
      }
    </>
  );
};

export default TooltipWrapper;
