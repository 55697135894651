import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    CreateLink,
    diffSourcePlugin,
    DiffSourceToggleWrapper,
    headingsPlugin,
    InsertTable,
    linkDialogPlugin,
    listsPlugin,
    ListsToggle,
    MDXEditor,
    quotePlugin,
    Separator,
    tablePlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    UndoRedo
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { useEffect, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { useTranslation } from "react-i18next"
import api from "../api"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg"
import { ReactComponent as LiveIcon } from "../assets/images/icons/ic-live.svg"
import { ReactComponent as CameraIcon } from "../assets/images/icons/ic-camera.svg"
import { ButtonStatus, LessonType, TagType } from "../common/constants"
import Button from "../components/Button"
import SimpleCollapsable from "../components/SimpleCollapsable"
import Card from "../components/cards/Card"
import TextInput from "../components/TextInput"
import { DialogStatus } from "../enums"
import typo from "../typography.module.css"
import AlertDialog from "./dialogs/AlertDialog"
import styles from "./LessonEditor.module.css"
import TagSelector from "./TagSelector"
import { capitalize } from '../utils'
import TabItem from './TabItem'


const LessonEditor = ({ lesson, onSave = () => { } }) => {
    const { t } = useTranslation()
    const [disableSave, setDisableSave] = useState(true)

    const [lessonEdit, setLessonEdit] = useState(lesson)
    const [tags, setTags] = useState([])
    const [thumbnail, setThumbnail] = useState(null)
    const [saveStatus, setSaveStatus] = useState(null)
    const [alert, setAlert] = useState({ open: false, title: '', text: '', actions: [], status: DialogStatus.Default })

    const dropzoneRef = useRef(null)

    useEffect(() => {
        const getTags = async (type) => {
            let tags = await api.get(`/admin/tags?type=${type ?? 'general'}`)
            setTags(tags)
        }

        getTags()

    }, [])

    useEffect(() => {
        if (lessonEdit.type === LessonType.Live) {
            setDisableSave(
                lessonEdit.name.trim() === '' ||
                lessonEdit.description.trim() === '' ||
                !lessonEdit.ends_at || !lessonEdit.starts_at ||
                lessonEdit.thumbnail?.trim() === ''
            )
        } else {
            setDisableSave(
                lessonEdit.name.trim() === '' ||
                lessonEdit.description.trim() === '' ||
                lessonEdit.thumbnail?.trim() === '' ||
                lessonEdit.video_url?.trim() === '' ||
                !lessonEdit.duration
            )
        }
    }, [lessonEdit])

    const update = async () => {
        setSaveStatus(ButtonStatus.Loading)
        try {
            const form = new FormData()
            form.append("name", lessonEdit.name)
            form.append("description", lessonEdit.description)
            form.append("thumbnail", lessonEdit.thumbnail)
            if (thumbnail) {
                form.append('thumbnailFile', thumbnail)
            }

            if (lesson.type === LessonType.Live) {
                form.append("starts_at", lessonEdit.starts_at)
                form.append("ends_at", lessonEdit.ends_at)
            }

            if (lesson.type === LessonType.Recorded) {
                form.append("video_url", lessonEdit.video_url)
                form.append("duration", lessonEdit.duration)
            }

            if (tags.length > 0) {
                form.append("tags", JSON.stringify(lessonEdit.tags.map(t => t.id)))
            }
            const res = await api.put(`/admin/lessons/${lessonEdit.id}`, form, { headers: { "Content-Type": "multipart/form-data" } })
            onSave(res)
            setSaveStatus(ButtonStatus.Success)
        } catch (e) {
            console.error(e)
            setSaveStatus(ButtonStatus.Error)
        }
    }

    const create = async () => {
        setSaveStatus(ButtonStatus.Loading)
        try {
            const form = new FormData()
            form.append("module_id", lessonEdit.module_id)
            form.append("name", lessonEdit.name)
            form.append("description", lessonEdit.description)
            form.append("thumbnail", lessonEdit.thumbnail)
            form.append("type", lessonEdit.type)

            if (thumbnail) {
                form.append('thumbnailFile', thumbnail)
            }
            if (tags.length > 0) {
                form.append("tags", JSON.stringify(lessonEdit.tags.map(t => t.id)))
            }

            if (lessonEdit.type === LessonType.Live) {
                form.append("starts_at", lessonEdit.starts_at)
                form.append("ends_at", lessonEdit.ends_at)
            }

            if (lessonEdit.type === LessonType.Recorded) {
                form.append("video_url", lessonEdit.video_url)
                form.append("duration", lessonEdit.duration)
            }

            const res = await api.post(`/admin/lessons`, form, { headers: { "Content-Type": "multipart/form-data" } })
            onSave(res)
            setSaveStatus(ButtonStatus.Success)
        } catch (e) {
            console.error(e)
            setSaveStatus(ButtonStatus.Error)
        }
    }

    const save = async () => {
        if (lessonEdit.id) {
            update()
        }
        else {
            create()
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {/* <div>{t("lessons.editTitle")}</div> */}
                <div className={styles.details}>
                    {
                        !lessonEdit.id &&
                        <div className={styles.typeContainer}>
                            <div className={styles.typeSwitch}>
                                <TabItem
                                    selected={lessonEdit.type === LessonType.Live}
                                    text={"Live"}
                                    IconComponent={LiveIcon}
                                    color={"var(--sf-red-rgb)"}
                                    style={{ padding: ".5rem" }}
                                    onClick={() => {
                                        setLessonEdit(l => ({ ...l, type: LessonType.Live }))
                                    }}
                                />
                                <TabItem
                                    selected={lessonEdit.type === LessonType.Recorded}
                                    text={"On demand"}
                                    IconComponent={CameraIcon}
                                    color={"var(--primary-rgb)"}
                                    style={{ padding: ".5rem" }}
                                    onClick={() => {
                                        setLessonEdit(l => ({ ...l, type: LessonType.Recorded }))
                                    }}
                                />
                            </div>
                        </div>
                    }
                    <SimpleCollapsable expanded={lessonEdit.type === LessonType.Recorded}>
                        <div className={styles.details} styles={{ paddingTop: ".5rem" }}>
                            <Card style={{ padding: ".5rem 1rem" }}>
                                <div className={styles.cardContent}>
                                    <div className={styles.sectionTitle}>Video URL</div>
                                    <TextInput
                                        style={{
                                            padding: "1px", border: "none", backgroundColor: "transparent",
                                            borderRadius: "0", fontSize: "1rem", fontWeight: 700, width: "100%"
                                        }}
                                        type="text"
                                        value={lessonEdit?.video_url}
                                        onKeyUp={(value) => {
                                            setLessonEdit(l => ({ ...l, video_url: value }))
                                        }} />
                                </div>
                            </Card>
                            <Card style={{ padding: ".5rem 1rem" }}>
                                <div className={styles.cardContent}>
                                    <div className={styles.sectionTitle}>Durata video (min)</div>
                                    <TextInput
                                        style={{
                                            padding: "1px", border: "none", backgroundColor: "transparent",
                                            borderRadius: "0", fontSize: "1rem", fontWeight: 700, width: "100%"
                                        }}
                                        type="number"
                                        value={lessonEdit?.duration}
                                        onKeyUp={(value) => {
                                            const v = parseInt(value)
                                            setLessonEdit(l => ({ ...l, duration: isNaN(v) ? null : v }))
                                        }} />
                                </div>
                            </Card>
                        </div>
                    </SimpleCollapsable>
                    <Card style={{ padding: ".5rem 1rem" }}>
                        <div className={styles.cardContent}>
                            <div className={styles.sectionTitle}>Nome</div>
                            <TextInput
                                type={"text"}
                                value={lesson.name ?? "-"}
                                style={{
                                    padding: "1px", border: "none", backgroundColor: "transparent",
                                    borderRadius: "0", fontSize: "1rem", fontWeight: 700, width: "100%"
                                }}
                                onKeyUp={v => setLessonEdit((prev) => {
                                    prev.name = v
                                    return { ...prev }
                                })}
                            />
                        </div>
                    </Card>
                    <div className={styles.tags}>
                        <TagSelector
                            selected={lessonEdit.tags.filter(t => t.type === TagType.General)}
                            options={tags.filter(t => t.type === TagType.General)}
                            placeholder={"Cerca tag"}
                            onAdd={(item) => {
                                const newOptions = tags.filter(t => t.id !== item.id)
                                setTags([...newOptions])

                                setLessonEdit((prev) => {
                                    if (!prev.tags.map(t => t.id).includes(item.id)) {
                                        prev.tags.push(item)
                                    }
                                    return { ...prev }
                                })
                            }}
                            onRemove={(item) => {
                                const newOptions = [...tags]
                                if (!newOptions.map(t => t.id).includes(item.id)) {
                                    newOptions.push(item)
                                }
                                setTags([...newOptions])

                                setLessonEdit((prev) => {
                                    prev.tags = prev.tags.filter(t => t.id !== item.id)
                                    return { ...prev }
                                })
                            }}
                        />
                    </div>
                    <Card style={{ padding: ".5rem 1rem" }}>
                        <div className={styles.cardContent}>
                            <div className={styles.sectionTitle}>Thumbnail URL</div>
                            <TextInput
                                disabled={lessonEdit?.thumbnail?.startsWith('blob:')}
                                style={{
                                    padding: "1px", border: "none", backgroundColor: "transparent",
                                    borderRadius: "0", fontSize: "1rem", fontWeight: 700, width: "100%"
                                }}
                                type="text" value={lessonEdit?.thumbnail} onKeyUp={(value) => {
                                    setLessonEdit((prev) => {
                                        prev.thumbnail = value ? value : ''
                                        return { ...prev }
                                    })
                                }} />
                        </div>
                    </Card>
                </div>
                <div className={styles.thumbnailSection}>
                    <Dropzone
                        onDrop={(files) => {
                            const thumbnailUrl = URL.createObjectURL(files[0])
                            setThumbnail(files[0])
                            setLessonEdit((prev) => {
                                prev.thumbnail = thumbnailUrl
                                return { ...prev }
                            })
                        }}
                        accept={
                            {
                                'image/jpeg': ['.jpg', '.jpeg'],
                                "image/png": ['.png'],
                                "image/svg+xml": ['.svg'],
                                "image/webp": ['.webp']
                            }
                        }
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                                <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                                    <input {...getInputProps()} />
                                    <div className={styles.dropzone} ref={dropzoneRef}>
                                        {lessonEdit.thumbnail &&
                                            <div className={styles.thumbnailWrapper}>
                                                <img src={lessonEdit?.thumbnail} alt="" className={styles.thumbnail} />
                                                <div className={styles.removeThumbnail} onClick={(e) => {
                                                    e.stopPropagation()
                                                    setThumbnail(null)
                                                    setLessonEdit((prev) => {
                                                        prev.thumbnail = ''
                                                        return { ...prev }
                                                    })
                                                }}>
                                                    <CloseIcon style={{ height: '16px', width: '16px' }} />
                                                </div>
                                            </div>
                                        }
                                        <div className={styles.dropzoneLabel}>
                                            Trascina o seleziona un'immagine.
                                        </div>
                                        <Button
                                            accentColor={'var(--tertiary)'}
                                            style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                            onClick={() => {
                                                if (dropzoneRef && dropzoneRef.current) {
                                                    dropzoneRef.current.click()
                                                }
                                            }}
                                        >
                                            SCEGLI IMMAGINE
                                        </Button>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>

                <Card style={{ padding: "0", overflow: "hidden" }}>
                    <div className={styles.cardContent} style={{ gap: 0 }}>
                        <div className={styles.sectionTitle} style={{ background: "var(--background-secondary-color)", padding: '0.5rem 1rem' }}>Descrizione</div>
                        <MDXEditor
                            className={styles.editor}
                            markdown={lessonEdit.description}
                            onChange={(value) => {
                                setLessonEdit((prev) => {
                                    prev.description = value
                                    return { ...prev }
                                })
                            }}
                            plugins={[
                                headingsPlugin(),
                                listsPlugin(),
                                quotePlugin(),
                                thematicBreakPlugin(),
                                linkDialogPlugin(),
                                diffSourcePlugin(),
                                tablePlugin(),
                                toolbarPlugin({
                                    toolbarContents: () => (
                                        <>
                                            {' '}
                                            <UndoRedo />
                                            <Separator />
                                            <BoldItalicUnderlineToggles />
                                            <Separator />
                                            <ListsToggle />
                                            <CreateLink />
                                            <InsertTable />
                                            <Separator />
                                            <BlockTypeSelect />
                                            <DiffSourceToggleWrapper options={["rich-text", "source"]} />
                                        </>
                                    )
                                })
                            ]}
                        />
                    </div>
                </Card>

                <SimpleCollapsable expanded={lessonEdit.type === LessonType.Live}>
                    <div>
                        <Card
                            style={{
                                padding: ".5rem 1rem",
                            }}>
                            <div className={styles.dateContainer}>
                                <div className={styles.cardContent}>
                                    <div className={styles.sectionTitle}>Data</div>
                                    <div className={styles.date}>
                                        <TextInput
                                            type="date"
                                            // disabled={new Date(lessonEdit.starts_at) < new Date()}
                                            value={lesson.starts_at ?? null}
                                            defaultValue={null}
                                            placeholder="Inizio Lezione"
                                            onKeyUp={(d) => {
                                                setLessonEdit((prev) => {
                                                    prev.starts_at = d.toISOString()
                                                    return { ...prev }
                                                })
                                            }}
                                        />
                                        <TextInput
                                            type="date"
                                            // disabled={new Date(lessonEdit.ends_at) < new Date()}
                                            value={lesson.ends_at ?? null}
                                            defaultValue={null}
                                            placeholder="Fine Lezione"
                                            onKeyUp={(d) => {
                                                setLessonEdit((prev) => {
                                                    prev.ends_at = d.toISOString()
                                                    return { ...prev }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </SimpleCollapsable>
                <div className={styles.save}>
                    <Button
                        status={saveStatus}
                        disabled={disableSave}
                        onClick={save}>
                        {t("lessons.save").toUpperCase()}
                    </Button>
                </div>
            </div>
            <AlertDialog
                open={alert.open}
                title={alert.title}
                text={alert.text}
                actions={alert.actions}
                status={alert.status}
                onClose={() => {
                    setAlert((prev) => {
                        prev.open = false
                        return { ...prev }
                    })
                }}
            />
        </div>
    )

}

export default LessonEditor
