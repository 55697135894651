import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-image-edit.svg"
import { ReactComponent as InsertImageIllustration } from "../assets/images/illustrations/il-insert-image.svg"
import { AccountStatus, AdminRole, ButtonStatus } from "../common/constants"
import { Each } from "../common/Each"
import { capitalize } from "../utils"
import styles from "./Admins.module.css"
import Button from "./Button"
import AdminCard from "./cards/AdminCard"
import Dialog from "./dialogs/Dialog"
import ImageCropper from "./ImageCropper"
import TextInput from "./TextInput"
import Select from "./Select"

const Admins = forwardRef(({ }, ref) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [admins, setAdmins] = useState([])
    const [filteredAdmins, setFilteredAdmins] = useState([])
    const [adminsLoading, setAdminLoading] = useState([])

    const [open, setOpen] = useState(false)
    const [profilePic, setProfilePic] = useState(null)
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [status, setStatus] = useState(AccountStatus.Suspended)
    const [imgFile, setImgFile] = useState(null)
    const [cropMode, setCropMode] = useState(false)
    const [addStatus, setAddStatus] = useState(null)
    const [isAdminValid, setIsAdminValid] = useState(false)

    const roleOptions = useMemo(() => {
        return [
            { id: 0, name: "Main", value: AdminRole.Main },
            { id: 1, name: "Sales", value: AdminRole.Sales },
        ]
    }, [])
    const [role, setRole] = useState(roleOptions[0])

    const dropzoneRef = useRef(null)
    const getAdmins = async () => {
        setAdminLoading(true)
        try {
            const admins = await api.get(`/admin/admins`)
            setAdmins(admins)
            setFilteredAdmins(admins)
        } catch (e) {
            console.error(e)
        }
        setAdminLoading(false)
    }
    useEffect(() => {
        getAdmins()
    }, [])

    useEffect(() => {
        setIsAdminValid(
            profilePic !== null &&
            name !== "" &&
            surname !== "" &&
            email !== "" &&
            email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        )
    }, [profilePic, name, surname, email])

    const addAdmin = useCallback(async () => {
        setAddStatus(ButtonStatus.Loading)
        try {
            const formData = new FormData()
            formData.append("name", name)
            formData.append("surname", surname)
            formData.append("email", email)
            formData.append("status", status)
            formData.append("role", roleOptions[role.id].value)

            if (profilePic) {
                let blob = await fetch(profilePic).then(r => r.blob());
                formData.append("picture", blob);
            }

            const admin = await api.post("/admin/admins", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            setAdmins(t => [...t, admin])
            setAddStatus(ButtonStatus.Success)
            setOpen(false)
            setProfilePic(null)
            setName("")
            setSurname("")
            setEmail("")
            setRole(roleOptions[0])
            setAddStatus(null)
            getAdmins()
        } catch (e) {
            console.error(e)
            setAddStatus(ButtonStatus.Error)
        }
    }, [name, surname, email, profilePic, status, role, roleOptions])

    const onAddClick = () => setOpen(true)

    useImperativeHandle(ref, () => {
        return {
            onAddClick
        }
    })

    const onSearch = useCallback(async (text) => {
        text = text.toLowerCase()
        setFilteredAdmins(
            admins.filter(t => (
                (t.name.trim().toLowerCase() +
                t.surname.trim().toLowerCase()).includes(text.replaceAll(' ', '').toLowerCase()) ||
                (t.surname.trim().toLowerCase() +
                t.name.trim().toLowerCase()).includes(text.replaceAll(' ', '').toLowerCase())
            ))
        )
    }, [admins])

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.sectionInner}>
                    <TextInput
                        onKeyUp={onSearch}
                        type="search"
                        placeholder={t("search")}
                        style={{
                            backgroundColor: "var(--background-secondary-color)",

                        }} />
                    <div className={styles.admins}>
                        {
                            adminsLoading &&
                            <Each of={[0, 1, 2]} render={() => <AdminCard loading />} />
                        }
                        {
                            !adminsLoading && filteredAdmins.length > 0 &&
                            <Each
                                of={filteredAdmins}
                                render={(admin) => (
                                    <AdminCard admin={admin} adminsLoading onClick={() => navigate(`/users/admins/${admin.id}`)} />
                                )}
                            />
                        }

                        {
                            adminsLoading === false && filteredAdmins.length === 0 &&
                            <div className={styles.noData}>Nessun admin trovato</div>
                        }
                    </div>
                </div>

            </div>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false)
                    setProfilePic(null)
                    setName("")
                    setSurname("")
                    setEmail("")
                    setRole(AdminRole.Main)
                    setAddStatus(null)
                }}
                title={`Aggiungi Admin`}
            >
                <div className={styles.dialog}>
                    <div className={styles.imageSelector}>
                        {!cropMode && !profilePic &&
                            <Dropzone
                                onDrop={(files) => {
                                    if (files[0]) {
                                        setImgFile(files[0])
                                        setCropMode(true)
                                    }
                                }}
                                accept={{
                                    'image/jpeg': [],
                                    'image/png': [],
                                    'image/heic': []
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section style={{ display: 'flex', width: '100%', }}>
                                        <div {...getRootProps()} style={{ display: 'flex', height: "100%", alignItems: "center", width: '100%' }}>
                                            <input {...getInputProps()} ref={dropzoneRef} />
                                            <div className={styles.editPictureDropzone}>
                                                <InsertImageIllustration></InsertImageIllustration>
                                                <div className={styles.editPictureLabel}>
                                                    {t('profile.dropImage')}
                                                </div>
                                                <Button
                                                    style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                    accentColor={"var(--tertiary)"}
                                                    onClick={() => dropzoneRef.current?.click()}
                                                >
                                                    {t('profile.chooseImage').toUpperCase()}
                                                </Button>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        }
                        {cropMode && !profilePic &&
                            <>
                                <ImageCropper
                                    src={URL.createObjectURL(imgFile)}
                                    onSave={(image) => {
                                        setProfilePic(image)
                                        setCropMode(false)
                                    }}
                                    onCancel={() => { setCropMode(false) }}
                                    compression={{ quality: 0.7, maxWidth: 300, maxHeight: 300 }}
                                >
                                </ImageCropper>
                            </>
                        }
                        {
                            profilePic &&
                            <div className={styles.profilePicPreview}>
                                <img className={styles.profilePic} src={profilePic} alt='' />
                                <Button
                                    style={{ padding: "0.7rem 1.5rem" }}
                                    accentColor={"var(--sf-orange)"}
                                    onClick={() => {
                                        setProfilePic(null)
                                        setCropMode(false)
                                    }}
                                >
                                    <EditIcon />
                                    CAMBIA
                                </Button>
                            </div>
                        }
                    </div>
                    <div className={styles.inputs}>
                        <Select selected={role} options={roleOptions} onSelect={(r) => setRole(r)} />
                        <TextInput value={name} type="text" placeholder={capitalize(t('profile.name'))} onKeyUp={v => setName(v)}></TextInput>
                        <TextInput value={surname} type="text" placeholder={capitalize(t('profile.surname'))} onKeyUp={v => setSurname(v)}></TextInput>
                        <TextInput value={email} type="text" placeholder={capitalize(t('profile.email'))} onKeyUp={v => setEmail(v)} />

                        <div className={styles.bottom}>
                            <div className={styles.switch}>
                                <div
                                    className={status === AccountStatus.Active ? `${styles.status} ${styles.active}` : `${styles.status} ${styles.active} ${styles.notSelected}`}
                                    onClick={() => setStatus(AccountStatus.Active)}
                                >
                                    ATTIVO
                                </div>
                                <div
                                    className={status === AccountStatus.Suspended ? `${styles.status} ${styles.suspended}` : `${styles.status} ${styles.suspended} ${styles.notSelected}`}
                                    onClick={() => setStatus(AccountStatus.Suspended)}
                                >
                                    SOSPESO
                                </div>
                            </div>

                            <Button
                                style={{ padding: '0.7rem 1rem' }}
                                accentColor={"var(--primary)"}
                                disabled={isAdminValid === false}
                                status={addStatus}
                                onClick={addAdmin}
                            >
                                AGGIUNGI
                                {
                                    addStatus === null &&
                                    <AddIcon />
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div >
    )
})

export default Admins
