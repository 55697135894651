import { useTranslation } from "react-i18next"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import Button from "./Button"
import HeaderFooterLayout from "./layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./Teachers.module.css"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import api from "../api"
import { Each } from "../common/Each"
import TeacherCard from "./cards/TeacherCard"
import { useNavigate } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Dialog from "./dialogs/Dialog"
import TextInput from "./TextInput"
import { capitalize } from "../utils"
import Tag from "./Tag"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg"
import TextareaAutosize from 'react-textarea-autosize'
import DropdownMultiSelection from "./DropdownMultiSelection"
import Dropzone from "react-dropzone"
import { ReactComponent as InsertImageIllustration } from "../assets/images/illustrations/il-insert-image.svg"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-image-edit.svg"
import ImageCropper from "./ImageCropper"
import { AccountStatus, ButtonStatus, Role } from "../common/constants"

const Teachers = forwardRef(({ role = Role.Teacher, }, ref) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [teachers, setTeachers] = useState([])
    const [filteredTeachers, setFilteredTeachers] = useState([])
    const [teachersLoading, setTeacherLoading] = useState([])

    const [open, setOpen] = useState(false)
    const [profilePic, setProfilePic] = useState(null)
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [qualification, setQualification] = useState("")
    const [bio, setBio] = useState("")
    const [status, setStatus] = useState(AccountStatus.Suspended)
    const [tagsList, setTagList] = useState([])
    const [tags, setTags] = useState([])
    const [options, setOptions] = useState([])
    const [imgFile, setImgFile] = useState(null)
    const [cropMode, setCropMode] = useState(false)
    const [addStatus, setAddStatus] = useState(null)
    const [isTeacherValid, setIsTeacherValid] = useState(false)

    const roleName = useMemo(() => role === Role.Teacher ? "docente" : "tutor", [role])
    const roleNamePlural = useMemo(() => role === Role.Teacher ? "docenti" : "tutor", [role])

    const dropzoneRef = useRef(null)
    useEffect(() => {
        const getTeachers = async () => {
            setTeacherLoading(true)
            try {
                const teachers = await api.get(`/admin/teachers?role=${role}`)
                setTeachers(teachers)
                setFilteredTeachers(teachers)
            } catch (e) {
                console.error(e)
            }
            setTeacherLoading(false)
        }

        const getTags = async (teacher) => {
            try {
                const tags = await api.get(`/admin/tags?type=general`)

                setTagList(tags)
                setOptions(tags)
            } catch (e) {
                console.error(e)
            }
        }

        getTeachers()
        getTags()
    }, [role])

    useEffect(() => {
        const newOptions = tagsList.filter((tl) => {
            return tags.map(t => t.id).includes(tl.id) === false
        })
        setOptions(newOptions)
    }, [tags])

    useEffect(() => {
        setIsTeacherValid(
            profilePic !== null &&
            name !== "" &&
            surname !== "" &&
            email !== "" &&
            email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) &&
            qualification !== ""
        )
    }, [profilePic, name, surname, email, qualification])

    const addTeacher = useCallback(async () => {
        setAddStatus(ButtonStatus.Loading)
        try {
            const formData = new FormData()
            formData.append("name", name)
            formData.append("surname", surname)
            formData.append("email", email)
            formData.append("qualification", qualification)
            formData.append("description", bio)
            formData.append("status", status)
            formData.append("role", role)
            formData.append("tags", JSON.stringify(tags.map(t => t.id)))

            if (profilePic) {
                let blob = await fetch(profilePic).then(r => r.blob());
                formData.append("picture", blob);
            }

            const teacher = await api.post("/admin/teachers", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            setTeachers(t => [...t, teacher])
            setAddStatus(ButtonStatus.Success)
            setOpen(false)
            setProfilePic(null)
            setName("")
            setSurname("")
            setEmail("")
            setQualification("")
            setBio("")
            setTags([])
            setOptions(tagsList)
            setAddStatus(null)
        } catch (e) {
            console.error(e)
            setAddStatus(ButtonStatus.Error)
        }
    }, [name, surname, email, profilePic, bio, qualification, tags, status, tagsList, role])

    const onAddClick = () => setOpen(true)

    useImperativeHandle(ref, () => {
        return {
            onAddClick
        }
    })

    const onSearch = useCallback(async (text) => {
        text = text.toLowerCase()
        setFilteredTeachers(
            teachers.filter(t => (
                (t.name.trim().toLowerCase() +
                t.surname.trim().toLowerCase()).includes(text.replaceAll(' ', '').toLowerCase()) ||
                (t.surname.trim().toLowerCase() +
                t.name.trim().toLowerCase()).includes(text.replaceAll(' ', '').toLowerCase())
            ))
        )
    }, [teachers])

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.sectionInner}>
                    <TextInput
                        onKeyUp={onSearch}
                        type="search"
                        placeholder={t("search")}
                        style={{
                            backgroundColor: "var(--background-secondary-color)",

                        }} />
                    <div className={styles.teachers}>
                        {
                            teachersLoading &&
                            <Each of={[0, 1, 2]} render={() => <TeacherCard loading />} />
                        }
                        {
                            !teachersLoading && filteredTeachers.length > 0 &&
                            <Each
                                of={filteredTeachers}
                                render={(teacher) => (
                                    <TeacherCard teacher={teacher} teachersLoading onClick={() => navigate(`/users/${role}s/${teacher.id}`)} />
                                )}
                            />
                        }

                        {
                            teachersLoading === false && filteredTeachers.length === 0 &&
                            <div className={styles.noData}>Nessun {`${roleName}`} trovato</div>
                        }
                    </div>
                </div>

            </div>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false)
                    setProfilePic(null)
                    setName("")
                    setSurname("")
                    setEmail("")
                    setQualification("")
                    setBio("")
                    setTags([])
                    setOptions(tagsList)
                    setAddStatus(null)
                }}
                title={`Aggiungi ${roleName}`}
            >
                <div className={styles.dialog}>
                    <div className={styles.imageSelector}>
                        {!cropMode && !profilePic &&
                            <Dropzone
                                onDrop={(files) => {
                                    if (files[0]) {
                                        setImgFile(files[0])
                                        setCropMode(true)
                                    }
                                }}
                                accept={{
                                    'image/jpeg': [],
                                    'image/png': [],
                                    'image/heic': []
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section style={{ display: 'flex', width: '100%', }}>
                                        <div {...getRootProps()} style={{ display: 'flex', height: "100%", alignItems: "center", width: '100%' }}>
                                            <input {...getInputProps()} ref={dropzoneRef} />
                                            <div className={styles.editPictureDropzone}>
                                                <InsertImageIllustration></InsertImageIllustration>
                                                <div className={styles.editPictureLabel}>
                                                    {t('profile.dropImage')}
                                                </div>
                                                <Button
                                                    style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                    accentColor={"var(--tertiary)"}
                                                    onClick={() => dropzoneRef.current?.click()}
                                                >
                                                    {t('profile.chooseImage').toUpperCase()}
                                                </Button>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        }
                        {cropMode && !profilePic &&
                            <>
                                <ImageCropper
                                    src={URL.createObjectURL(imgFile)}
                                    onSave={(image) => {
                                        setProfilePic(image)
                                        setCropMode(false)
                                    }}
                                    onCancel={() => { setCropMode(false) }}
                                    compression={{ quality: 0.7, maxWidth: 300, maxHeight: 300 }}
                                >
                                </ImageCropper>
                            </>
                        }
                        {
                            profilePic &&
                            <div className={styles.profilePicPreview}>
                                <img className={styles.profilePic} src={profilePic} alt='' />
                                <Button
                                    style={{ padding: "0.7rem 1.5rem" }}
                                    accentColor={"var(--sf-orange)"}
                                    onClick={() => {
                                        setProfilePic(null)
                                        setCropMode(false)
                                    }}
                                >
                                    <EditIcon />
                                    CAMBIA
                                </Button>
                            </div>
                        }
                    </div>
                    <div className={styles.inputs}>
                        <TextInput value={name} type="text" placeholder={capitalize(t('profile.name'))} onKeyUp={v => setName(v)}></TextInput>
                        <TextInput value={surname} type="text" placeholder={capitalize(t('profile.surname'))} onKeyUp={v => setSurname(v)}></TextInput>
                        <TextInput value={email} type="text" placeholder={capitalize(t('profile.email'))} onKeyUp={v => setEmail(v)} />
                        <TextInput value={qualification} type="text" placeholder={capitalize(t('profile.qualification'))} onKeyUp={v => setQualification(v)} />
                        <TextareaAutosize
                            value={bio}
                            minRows={3} maxRows={10}
                            type="text"
                            placeholder={capitalize(t("profile.bio"))}
                            className={styles.textArea}
                            onChange={e => setBio(e.target.value)} />

                        <DropdownMultiSelection
                            placeholder={capitalize(t("profile.skills"))}
                            options={options}
                            selectedOptions={tags}
                            onSelect={(newTags) => setTags(newTags)}
                            itemsRender={(item, index) => (
                                <Tag tag={item} Icon={tags.find(t => t.id === item.id) ? CloseIcon : null}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        const idx = tags.findIndex(t => t.id === item.id)
                                        if (idx >= 0) {
                                            tags.splice(idx, 1)
                                            setTags([...tags])
                                        } else {
                                            setTags([...tags, item])
                                        }
                                    }}
                                />
                            )}
                        />
                        <div className={styles.bottom}>
                            <div className={styles.switch}>
                                <div
                                    className={status === AccountStatus.Active ? `${styles.status} ${styles.active}` : `${styles.status} ${styles.active} ${styles.notSelected}`}
                                    onClick={() => setStatus(AccountStatus.Active)}
                                >
                                    ATTIVO
                                </div>
                                <div
                                    className={status === AccountStatus.Suspended ? `${styles.status} ${styles.suspended}` : `${styles.status} ${styles.suspended} ${styles.notSelected}`}
                                    onClick={() => setStatus(AccountStatus.Suspended)}
                                >
                                    SOSPESO
                                </div>
                            </div>

                            <Button
                                style={{ padding: '0.7rem 1rem' }}
                                accentColor={"var(--primary)"}
                                disabled={isTeacherValid === false}
                                status={addStatus}
                                onClick={addTeacher}
                            >
                                AGGIUNGI
                                {
                                    addStatus === null &&
                                    <AddIcon />
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div >
    )
})

export default Teachers
