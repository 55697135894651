import styles from "./StatisticCard.module.css"
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronFilledIcon } from "../../assets/images/icons/ic-chevron-filled.svg"
import { ReactComponent as EqualIcon } from "../../assets/images/icons/ic-equal.svg"
import { useEffect, useMemo, useState } from "react";

const StatisticsCard = ({
  Icon = null,
  color = "",
  backgroundColor = "",
  value = "",
  label = "",
  increment = null,
  style = {},
  format = (v) => v
}) => {

  const { t } = useTranslation();

  const [count, setCount] = useState(0);

  const duration = useMemo(() => 1000, [])

  useEffect(() => {
    const easeOutExpo = (t) => (t === 1 ? 1 : 1 - Math.pow(2, -5 * t));

    let startTime = null;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);

      // Applichiamo la funzione di easing
      const easedProgress = easeOutExpo(progress);
      setCount(Math.floor(easedProgress * value));

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    }
    requestAnimationFrame(animate);
  }, [value, duration]);

  const _increment = useMemo(() => {
    if (typeof increment === "number") {
      if (increment > 0) {
        return {
          value: `+${increment}`,
          icon: <ChevronFilledIcon className={styles.incrementIcon} />
        }
      }
      if (increment === 0) {
        return {
          value: `${increment}`,
          icon: <EqualIcon className={styles.equalIcon} />
        }
      }
      return {
        value: `-${increment}`,
        icon: <ChevronFilledIcon className={`${styles.incrementIcon} ${styles.reverse}`} />
      }
    }
    return {
      value: increment,
      icon: <ChevronFilledIcon className={styles.incrementIcon} />
    }
  }, [increment])

  return (
    <div className={styles.container} style={style}>
      {
        (Icon || increment) &&
        <div className={styles.header}>
          {
            Icon &&
            <div className={styles.iconContainer} style={{ backgroundColor: `rgba(var(${color.split("(")[1].split(")")[0]}-rgb), 20%)` }}>
              <Icon className={styles.icon} style={{ color }} />
            </div>
          }
          {
            increment !== null &&
            <div className={styles.increment}>
              <div className={styles.incrementLeft}>
                <div className={styles.incrementValue}>{_increment.value}</div>
                <div className={styles.incrementLabel}>{t("profile.statistics.thisMonth")}</div>
              </div>
              {_increment.icon}
            </div>
          }
        </div>
      }
      <div className={styles.content}>
        <div className={styles.value}>{format(count)}</div>
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  )
}

export default StatisticsCard
