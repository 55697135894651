import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import api from "../api"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-image-edit.svg"
import { ReactComponent as StopIcon } from "../assets/images/icons/ic-stop.svg"
import { ReactComponent as UnlockIcon } from "../assets/images/icons/ic-unlock.svg"
import { ReactComponent as InsertImageIllustration } from "../assets/images/illustrations/il-insert-image.svg"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png"
import { AccountStatus, AdminRole, ButtonStatus } from "../common/constants"
import Back from "../components/Back"
import Button from "../components/Button"
import AlertDialog from "../components/dialogs/AlertDialog"
import Dialog from "../components/dialogs/Dialog"
import ImageCropper from "../components/ImageCropper"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Skeleton from "../components/Skeleton"
import TextInput from "../components/TextInput"
import { DialogStatus } from "../enums"
import { capitalize } from "../utils"
import styles from "./Admin.module.css"
import Select from "../components/Select"

const Admin = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { adminId, tutorId } = useParams()
  const id = adminId ?? tutorId

  const [admin, setAdmin] = useState(null)
  const [adminLoading, setAdminLoading] = useState(false)
  const [profilePic, setProfilePic] = useState(null)
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [hasChanges, setHasChanges] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [imgFile, setImgFile] = useState(null)
  const [cropMode, setCropMode] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: "",
    status: DialogStatus.Default,
    actions: []
  })

  const roleOptions = useMemo(() => {
    return [
      { id: 0, name: "Main", value: AdminRole.Main },
      { id: 1, name: "Sales", value: AdminRole.Sales },
    ]
  }, [])
  const [role, setRole] = useState(roleOptions[0])

  const [savingTimeout, setSavingTimeout] = useState(null)
  const [savingStatus, setSavingStatus] = useState(null)

  const dropzoneRef = useRef(null)

  useEffect(() => {
    const getAdmin = async () => {
      setAdminLoading(true)
      try {
        const admin = await api.get(`/admin/admins/${id}`)
        setAdmin(admin)
        setAdminLoading(false)
        return admin
      } catch (e) {
        setAdminLoading(false)
        console.error(e)
      }
    }

    getAdmin()

    return () => {
      if (savingTimeout) {
        clearTimeout(savingTimeout)
      }
    }
  }, [])


  useEffect(() => {
    if (admin) {
      setName(admin.name)
      setSurname(admin.surname)
      setRole(roleOptions.find(r => r.value === admin.role))
    }
  }, [admin])

  useEffect(() => {
    if (admin) {
      setHasChanges(
        (name !== admin.name) ||
        (surname !== admin.surname) ||
        (role.value !== admin.role) ||
        ((profilePic && profilePic !== admin.profilePic) ?? false)
      )
    }
  }, [admin, name, surname, role, profilePic])

  useEffect(() => {
    if (savingStatus === ButtonStatus.Success || savingStatus === ButtonStatus.Error) {
      setSavingTimeout(setTimeout(r => setSavingStatus(null), 3000))
    }
  }, [savingStatus])

  const saveChanges = async () => {
    setSavingStatus(ButtonStatus.Loading)
    try {
      const formData = new FormData()
      formData.append("name", name)
      formData.append("surname", surname)
      formData.append("role", roleOptions[role.id].value)

      if (profilePic) {
        let blob = await fetch(profilePic).then(r => r.blob());
        formData.append("picture", blob, `${admin.id}-picture.jpg`);
      }

      try {
        const admin = await api.put(`/admin/admins/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        setAdmin(admin)
      } catch (e) {
        throw new Error("Non è stato possibile salvare le modifiche.")
      }

      // setDialogOpen(true)
      setAlert({ open: true, title: t('profile.saved'), message: t('profile.changesSaved'), status: DialogStatus.Success })
      setSavingStatus(ButtonStatus.Success)
    } catch (e) {
      console.error(e)
      setSavingStatus(ButtonStatus.Error)
    }
  }

  const resetAlert = useCallback(() => {
    setAlert({
      open: false,
      title: "",
      message: "",
      status: DialogStatus.Default,
      actions: []
    })
  }, [])

  const changeAccountStatus = useCallback(async (status) => {
    setAlert(a => { return { ...a, status: DialogStatus.Loading } })

    try {
      const admin = await api.patch(`/admin/admins/${id}`, {
        status
      })
      setAdmin(admin)
      setAlert(a => { return { ...a, status: DialogStatus.Success } })
    } catch (e) {
      console.error(e)
      setAlert(a => { return { ...a, status: DialogStatus.Error } })
    }
  }, [id])

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Admin</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <Back onClick={() => navigate(-1)} />
            <div className={styles.content}>
              <div className={styles.leftColumn}>
                <div className={styles.admin}>
                  <img className={styles.profilePic} src={(profilePic || admin?.picture) ?? UserPlaceholder} alt='' />
                  {
                    adminLoading &&
                    <Skeleton type="rect" width="164px" height="24px" borderRadius={"12px"} />
                  }
                  {
                    !adminLoading &&
                    <div className={styles.bio}>
                      <div className={styles.username}>{`${admin?.name} ${admin?.surname}`}</div>
                      <div className={styles.email}>{admin?.email}</div>
                    </div>

                  }
                  <Button
                    accentColor={"var(--tertiary)"}
                    onClick={() => {
                      setDialogOpen(true)
                    }}
                  >
                    <EditIcon />
                    {t("profile.edit").toUpperCase()}
                  </Button>
                  {
                    admin?.status === AccountStatus.Active &&
                    <Button
                      accentColor={"var(--secondary)"}
                      onClick={() => {
                        setAlert({
                          open: true,
                          title: "Sospensione amministratore",
                          message: "L'amministratore non potrà più accedere alla piattaforma backoffice. Sei sicuro di voler procedere?",
                          status: DialogStatus.Default,
                          actions: [
                            {
                              label: "ANNULLA",
                              onClick: resetAlert,
                              inverse: true
                            },
                            {
                              label: "SI, SOSPENDI",
                              onClick: () => changeAccountStatus(AccountStatus.Suspended),
                            }
                          ]
                        })
                      }}
                    >
                      <StopIcon style={{ transform: "rotate(-45deg)" }} />
                      SOSPENDI
                    </Button>
                  }

                  {
                    admin?.status === AccountStatus.Suspended &&
                    <Button
                      accentColor={"var(--primary)"}
                      onClick={() => {
                        setAlert({
                          open: true,
                          title: "Attivazione amministratore",
                          message: "L'amministratore potrà accedere alla piattaforma backoffice. Sei sicuro di voler procedere?",
                          status: DialogStatus.Default,
                          actions: [
                            {
                              label: "ANNULLA",
                              onClick: resetAlert,
                              inverse: true
                            },
                            {
                              label: "SI, ATTIVA",
                              onClick: () => changeAccountStatus(AccountStatus.Active),
                            }
                          ]
                        })
                      }}
                    >
                      <UnlockIcon />
                      ATTIVA
                    </Button>
                  }

                </div>
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.card}>
                  <div className={styles.cardTitle}>{t("profile.editTitle")}</div>
                  <div className={styles.inputs}>
                    <div className={styles.row}>
                      <TextInput value={admin?.name} type="text" placeholder={capitalize(t('profile.name'))} onKeyUp={v => setName(v)}></TextInput>
                      <TextInput value={admin?.surname} type="text" placeholder={capitalize(t('profile.surname'))} onKeyUp={v => setSurname(v)}></TextInput>
                    </div>
                    <Select selected={role} options={roleOptions} onSelect={(r) => setRole(r)} />
                  </div>
                  <div style={{ alignSelf: "flex-end" }}>
                    <Button
                      status={savingStatus}
                      accentColor={"var(--primary)"}
                      style={{ padding: "0.8rem 1.5rem" }}
                      disabled={hasChanges === false}
                      onClick={saveChanges}
                    >
                      {t("profile.saveChanges").toUpperCase()}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <Dialog
              open={dialogOpen}
              style={{
                width: "30vw",
                maxWidth: "512px"
              }}
              onClose={() => {
                setDialogOpen(false)
                setCropMode(false)
              }}
              title={"Immagine del profilo"}>
              <div className={styles.dialog}>
                {!cropMode &&
                  <Dropzone
                    onDrop={(files) => {
                      if (files[0]) {
                        setImgFile(files[0])
                        setCropMode(true)
                      }
                    }}
                    accept={{
                      'image/jpeg': [],
                      'image/png': [],
                      'image/heic': []
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section style={{ display: 'flex', width: '100%' }}>
                        <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                          <input {...getInputProps()} ref={dropzoneRef} />
                          <div className={styles.editPictureDropzone}>
                            <InsertImageIllustration></InsertImageIllustration>
                            <div className={styles.editPictureLabel}>
                              {t('profile.dropImage')}
                            </div>
                            <Button
                              style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                              accentColor={"var(--tertiary)"}
                              onClick={() => dropzoneRef.current?.click()}
                            >
                              {t('profile.chooseImage').toUpperCase()}
                            </Button>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                }
                {cropMode &&
                  <>
                    <ImageCropper
                      src={URL.createObjectURL(imgFile)}
                      onSave={(image) => {
                        setProfilePic(image)
                        setCropMode(false)
                        setDialogOpen(false)
                      }}
                      onCancel={() => { setCropMode(false) }}
                      compression={{ quality: 0.7, maxWidth: 300, maxHeight: 300 }}
                    >
                    </ImageCropper>
                  </>
                }
              </div>
            </Dialog>

            <AlertDialog
              open={alert.open}
              title={alert.title}
              text={alert.message}
              onClose={alert.onClose ?? resetAlert}
              actions={alert.actions}
              status={alert.status}
            />
          </div>
        </div>
      </div>
    </HeaderFooterLayout >
  )
}

export default Admin
