import Card from "./Card"
import styles from "./OrderCard.module.css"
import typo from "../../typography.module.css"
import { OrderStatus } from "../../common/constants"
import { useEffect, useMemo, useRef, useState } from "react"
import { Each } from "../../common/Each"
import { formatPrice } from "../../utils"
import SimpleCollapsable from "../../components/SimpleCollapsable"
import Button from "../Button"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import { useNavigate } from 'react-router-dom';
import Skeleton from "../Skeleton"
import StatusBadge from "../StatusBadge"

const OrderCard = ({
    order,
    loading = false
}) => {

    const [open, setOpen] = useState(false)
    const [picture, setPicture] = useState(order?.user?.picture ?? UserPlaceholder)

    const navigate = useNavigate()

    const status = useMemo(() => {
        if (!order) {
            return null;
        }

        const { status } = order
        switch (status) {
            case OrderStatus.Pending:
                return {
                    label: "In Attesa",
                    color: "var(--sf-gray)"
                }
            case OrderStatus.Processing:
                return {
                    label: "In elaborazione",
                    color: "var(--sf-yellow)"
                }
            case OrderStatus.Succeeded:
                return {
                    label: "Completato",
                    color: "var(--sf-light-green)"
                }
            case OrderStatus.Failed:
                return {
                    label: "Errore",
                    color: "var(--sf-red)"
                }
            case OrderStatus.Refunded:
                return {
                    label: "Rimborsato",
                    color: "var(--sf-blue)"
                }
            default: return null
        }
    }, [order])

    return (
        <Card>
            <div className={styles.container}>
                {
                    loading === false &&
                    <>
                        <div className={styles.header}>
                            <div className={styles.ids}>
                                <div className={typo.caption}>{new Date(order.completed_at ?? order.created_at).toLocaleString("it-IT")}</div>
                                <div className={typo.caption}>{order.order_no}</div>
                                <div className={typo.caption}>stripe id: {order.intent_id}</div>
                            </div>
                            <StatusBadge {...status} />
                        </div>
                        <div className={styles.content}>
                            <div className={styles.student}
                                onClick={() => navigate(`/users/students/${order.user.id}`)}
                            >
                                <img className={styles.picture} src={picture} alt="" onError={() => setPicture(UserPlaceholder)} />
                                <div className={styles.info}>
                                    <div className={styles.name}>{order.user.name} {order.user.surname}</div>
                                    <div className={styles.email}>{order.user.email}</div>
                                </div>
                            </div>
                            <div> {order.products.length} {order.products.length === 0 || order.products.length > 1 ? "Prodotti" : "Prodotto"} </div>
                            {/* <div> Totale {formatPrice(order.products.map(p => p.discounted_price).reduce((a, c) => a += c, 0))} €</div> */}
                            <div> Totale {formatPrice(order.amount / 100)} €</div>
                            <Button
                                appearance="text"
                                onClick={() => setOpen(o => !o)}
                            >
                                {open ? "Nascondi dettagli" : "Mostra dettagli"}
                            </Button>
                        </div>
                        <SimpleCollapsable
                            expanded={open}
                        >
                            <div>
                                <div className={styles.headers}>
                                    <div className={styles.productHeader}>N.</div>
                                    <div className={styles.productHeader}>Prodotto</div>
                                    <div className={styles.productHeader}>Prezzo</div>
                                    <div className={styles.productHeader}>Sconto</div>
                                    <div className={styles.productHeader}>Totale</div>
                                </div>
                                <div style={{ borderTop: "1px solid rgba(var(--text-color-rgb), 42%)", margin: "4px 0" }} />
                                <div>
                                    <Each
                                        of={order.products}
                                        render={(product, index) => (
                                            <div className={styles.product}>
                                                <div className={styles.productIndex}>{index + 1}.</div>
                                                <div className={styles.productName}>{product.name}</div>
                                                <div className={styles.productPrice}>{formatPrice(product.price)}</div>
                                                <div className={styles.productDiscount}>{Math.round((product.price - product.discounted_price) / product.price * 100)}%</div>
                                                <div className={styles.productDiscountedPrice}>{formatPrice(product.discounted_price)}</div>
                                            </div>
                                        )}
                                    />
                                </div>
                                <div style={{ borderTop: "1px solid rgba(var(--text-color-rgb), 42%)", margin: "4px 0" }} />

                                <div className={styles.totalContainer}>
                                    <div className={styles.totalContent}>
                                        <div className={styles.totalHeaders}>
                                            <div>Subtotale</div>
                                            <div>Sconti</div>
                                            <div>Totale</div>
                                        </div>
                                        <div className={styles.totalValues}>
                                            <div>{formatPrice(order.products.map(p => p.price).reduce((a, c) => a += c, 0))} €</div>
                                            <div>{formatPrice(order.products.reduce((a, c) => {
                                                const { price, discounted_price } = c
                                                a += price - discounted_price
                                                return a
                                            }, 0))} €
                                            </div>
                                            <div>{formatPrice(order.products.map(p => p.discounted_price).reduce((a, c) => a += c, 0))} €</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SimpleCollapsable>
                    </>
                }
                {
                    loading &&
                    <>
                        <div className={styles.header}>
                            <div className={styles.ids}>
                                <Skeleton height={"13px"} width={"96px"} borderRadius={"8px"} />
                                <Skeleton height={"13px"} width={"124px"} borderRadius={"8px"} />
                                <Skeleton height={"13px"} width={"196px"} borderRadius={"8px"} />
                            </div>
                            <div className={styles.statusContainer}>
                                <Skeleton height={"23px"} width={"100px"} borderRadius={"8px"} />
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.student}>
                                <Skeleton height={"48px"} width={"48px"} type="circle" />
                                <div className={styles.info} style={{ gap: ".5rem" }}>
                                    <Skeleton height={"18px"} width={"190px"} borderRadius={"8px"} />
                                    <Skeleton height={"12px"} width={"164px"} borderRadius={"8px"} />
                                </div>
                            </div>
                            <Skeleton width={"78px"} height={"22px"} borderRadius={"8px"} />
                            <Skeleton width={"115px"} height={"22px"} borderRadius={"8px"} />
                            <Skeleton width={"120px"} height={"20px"} borderRadius={"8px"} />
                        </div>
                    </>
                }
            </div>
        </Card>
    )

}

export default OrderCard
