import { useContext, useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg"
import { NotificationType } from "../common/constants"
import { Each } from "../common/Each"
import MainContext from "../common/MainContext"
import Button from "../components/Button"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Loader from "../components/Loader"
import typo from "../typography.module.css"
import { dropDuplicates, formatDateV2, formatTimeV2 } from "../utils"
import styles from "./Notifications.module.css"

const Notifications = () => {

    const navigate = useNavigate()
    const context = useContext(MainContext)
    const [grouped, setGrouped] = useState([])
    const [notifications, setNotifications] = useState([])
    const [metadata, setMetadata] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
        getNotifications()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const readNotifications = async () => {
            try {
                let ids = notifications.filter(n => !n.read_at).map(n => n.id)
                if (ids.length > 0) {
                    let response = await api.put("/notifications/read", { ids: ids })
                    let clone = structuredClone(context.notifications)
                    clone.metadata.unread = response.unread
                    context.setNotifications(clone)
                }
            }
            catch (e) {
                console.error(e)
            }
        }

        if (notifications) {
            readNotifications()
        }
    }, [notifications])

    const getNotifications = async (page = 1, per = 10) => {
        setLoading(true)
        try {
            let n = await api.get(`/notifications?page=${page}&per=${per}`)

            let all = dropDuplicates(notifications.concat(n.page))
            let grouped = groupByDate(all)

            setNotifications(all)
            setMetadata(n.metadata)
            setGrouped(grouped)
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    const groupByDate = (objectsArray) => {
        const groupedObjects = {};

        objectsArray.forEach(obj => {
            const date = new Date(obj.created_at);

            const year = `${date.getFullYear()}`
            const month = date.getMonth() > 9 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`
            const day = date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`
            const dateString = `${year}-${month}-${day}`

            if (!groupedObjects[dateString]) {
                groupedObjects[dateString] = [];
            }

            groupedObjects[dateString].push(obj);
        });

        // Ordina le chiavi (le date) in ordine decrescente
        const sortedKeys = Object.keys(groupedObjects).sort((a, b) => new Date(b) - new Date(a));

        // Costruisci un nuovo oggetto ordinato usando le chiavi ordinate
        const sortedGroupedObjects = {};
        sortedKeys.forEach(key => {
            sortedGroupedObjects[key] = groupedObjects[key];
        });

        return sortedGroupedObjects;
    }

    const handleLoadMore = async () => {
        getNotifications(metadata.page + 1)
    }

    const handleNotificationClick = (notification) => {
        if (notification.url) {
            context.setDropdown(null)
            if (notification.url.includes('.')) {
                window.location.href = notification.url
            }
            else {
                navigate(notification.url)
            }
        }
    }

    const getNotificationColor = (type) => {
        switch (type) {
            case NotificationType.LessonLive:
            case NotificationType.LessonEdit:
                return "var(--secondary-rgb)"
            case NotificationType.ExerciseReminder:
            case NotificationType.ExerciseResult:
            case NotificationType.ExerciseExpired:
                return "var(--sf-yellow-rgb)"
            case NotificationType.MaterialUpload:
                return "var(--sf-green-rgb)"
            case NotificationType.Announcement:
                return "var(--tertiary-rgb)"
            default:
                return "235, 235, 235"
        }
    }

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Notifiche</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section} style={{ paddingBottom: 0 }}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title}>Le mie notifiche</div>
                    </div>
                </div>
                <div className={styles.section} style={{ paddingTop: 0 }}>
                    <div className={styles.sectionInner} style={{ paddingTop: 0 }}>
                        <Each of={Object.entries(grouped)} render={([key, value]) => {
                            return (
                                <div className={styles.day}>
                                    <div className={styles.date}>{formatDateV2(key, {weekday: 'long', day: 'numeric', month: 'long'}).toUpperCase()}</div>
                                    <Each of={value} render={(notification) => {
                                        return (
                                            <div className={`${styles.notificationBackground}`}
                                                style={{ background: `linear-gradient(90deg, rgba(${getNotificationColor(notification.type)}, ${notification.read_at ? '12%' : '100%'}) 0%, rgba(${getNotificationColor(notification.type)},  ${notification.read_at ? '12%' : '100%'}) 5%, #FFFFFF 5%)` }}>
                                                <div className={styles.notification} style={{ cursor: notification.url ? 'pointer' : 'default' }} onClick={() => { handleNotificationClick(notification) }}>
                                                    {notification.thumbnail &&
                                                        <img src={notification.thumbnail} alt="" className={styles.thumbnail} />
                                                    }
                                                    <div className={styles.info}>
                                                        <div className={styles.title}>
                                                            {notification.title}
                                                        </div>
                                                        <div className={`${styles.message} ${notification.read_at ? styles.read : ''}`}>
                                                            {notification.message}
                                                        </div>
                                                    </div>
                                                    <div className={styles.time}>
                                                        {formatTimeV2(notification.created_at)}
                                                        <ClockIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }} />
                                </div>
                            )
                        }} />
                        {notifications.length === 0 && !loading &&
                            <div style={{ display: 'flex', padding: '1rem', minHeight: '200px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div className={typo.headline}>Non ci sono notifiche.</div>
                            </div>
                        }
                        {notifications.length < metadata.total && !loading &&
                            <Button appearance="text" style={{ fontSize: '1rem' }} fullWidth onClick={handleLoadMore}>vedi altre</Button>
                        }
                        {loading &&
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%', height: '200px', justifyContent: 'center', alignItems: 'center' }}>
                                <Loader />
                                <div className={typo.headline}>Carico le notifiche...</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )
}

export default Notifications