import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import api from "../api";
import { ReactComponent as MoneyIcon } from "../assets/images/icons/ic-money.svg";
import { ReactComponent as TrendIcon } from "../assets/images/icons/ic-trend.svg";
import { ReactComponent as EmptyIllustration } from "../assets/images/illustrations/il-empty.svg";
import { Each } from "../common/Each";
import OrderCard from "../components/cards/OrderCard";
import StatisticsCard from "../components/cards/StatisticCard";
import EmptyContent from "../components/EmptyContent";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import Pagination from "../components/Pagination";
import TextInput from "../components/TextInput";
import typo from "../typography.module.css";
import { formatPrice } from "../utils";
import styles from "./Orders.module.css";
import { TimeFilter } from "../common/constants";

const Orders = () => {
    const { t } = useTranslation();

    const [showPagination, setShowPagination] = useState(true)
    const [page, setPage] = useState(0)
    const [per, setPer] = useState(10)
    const [orders, setOrders] = useState([])
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(true)
    const [stats, setStats] = useState({
        numberOrders: 0,
        totalAmount: 0,
        thisMonthOrders: 0,
        thisMonthAmount: 0
    })

    useEffect(() => {
        const getStatistics = async () => {
            try {
                const total = await api.get("/admin/orders/statistics")
                const thisMonth = await api.get(`/admin/orders/statistics?filter=${TimeFilter.CurrentMonth}`)

                const numberOrders = Object.values(total.orders).reduce((a, c) => a += c, 0)
                const thisMonthOrders = Object.values(thisMonth.orders).reduce((a, c) => a += c, 0)

                setStats({
                    numberOrders,
                    totalAmount: total.amount.sum,
                    thisMonthOrders,
                    thisMonthAmount: thisMonth.amount.sum
                })
            } catch (e) {
                console.error(e)
            }
        }
        getStatistics()
    }, [])

    useEffect(() => {
        getOrders()
    }, [page])

    const getOrders = async (q = null) => {
        setLoading(true)
        try {
            const data = q ?
                await api.get(`/admin/orders?q=${q}`) :
                await api.get(`/admin/orders?page=${page}&per=${per}`)

            const { orders, count } = data
            setOrders(orders)
            setCount(count)
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
        if (loadingPage) {
            setLoadingPage(false)
        }
    }

    const onSearch = async (searchText) => {
        if (loading)
            return

        if (searchText.length > 0) {
            await getOrders(searchText.toLowerCase())
            setShowPagination(false)
        } else {
            await getOrders()
            setShowPagination(true)
        }
    }

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Ordini
                    </title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>Ordini</div>
                        <div className={styles.statisticsContainer}>
                            <StatisticsCard
                                label="Ordini completati"
                                value={stats.numberOrders}
                                color="var(--sf-blue)"
                                increment={stats.thisMonthOrders}
                                Icon={TrendIcon}
                            />
                            <StatisticsCard
                                label="Totale acquistato"
                                value={stats.totalAmount / 100}
                                color="var(--sf-yellow)"
                                increment={`+${formatPrice(stats.thisMonthAmount / 100)}€`}
                                Icon={MoneyIcon}
                                format={(v) => `${formatPrice(v)}€`}
                            />
                        </div>

                        <TextInput
                            onKeyUp={onSearch}
                            type="search"
                            placeholder={t("search")} />

                        <div className={styles.orders}>
                            {
                                loading &&
                                <Each
                                    of={Array(per).fill("")}
                                    render={() => (
                                        <OrderCard loading={true} />
                                    )}
                                />
                            }
                            {
                                !loading &&
                                <Each
                                    of={orders}
                                    render={(order, index) => (
                                        <OrderCard order={order} />
                                    )}
                                />
                            }
                            {
                                !loading && orders.length === 0 &&
                                <EmptyContent
                                    Illustration={EmptyIllustration}
                                    title={"Nessun ordine trovato"}
                                />
                            }
                        </div>
                        {
                            showPagination &&
                            <Pagination total={count} per={per} page={page} onChange={p => setPage(p)} loading={loadingPage} />
                        }
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>

    );
};

export default Orders;
