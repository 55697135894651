import { useEffect, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import api from "../../api";
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/icons/ic-download.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg";
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png";
import { Each } from "../../common/Each";
import { formatDateV2, formatFileSize, formatTimeV2 } from "../../utils";
import Button from "../Button";
import Loader from "../Loader";
import SimpleCollapsable from '../SimpleCollapsable';
import TruncatedText from '../TruncatedText';
import Card from "./Card";
import styles from "./ExerciseCard.module.css";
import { useNavigate } from "react-router-dom";
import MeatBallsMenu from "../MeatBallsMenu";
import Skeleton from "../Skeleton";

const ExerciseCard = ({ exercise, loading, onDelete = () => { } }) => {

    const navigate = useNavigate()
    const [expanded, setExpanded] = useState(false)
    const [materialExpanded, setMaterialExpanded] = useState(false)
    const [picture, setPicture] = useState(exercise?.teacher?.picture)
    const [loadingSubmissions, setLoadingSubmissions] = useState(false)
    const [_exercise, setExercise] = useState(null)

    useEffect(() => {
        if (exercise) {
            setExercise(exercise)
            setPicture(exercise?.teacher?.picture)
        }
    }, [exercise])

    const materialsNumber = useMemo(() => {
        if (!exercise) return []
        const { medias } = exercise

        return medias?.length ?? 0
    }, [exercise])

    const onClick = async () => {
        setExpanded(e => !e)

        if (!_exercise.submissions) {
            setLoadingSubmissions(true)
            try {
                const submissions = await api.get(`/admin/exercises/${exercise.id}/users`)
                setExercise(e => ({ ...e, submissions }))
            } catch (e) {
                console.error(e)
            }
            setLoadingSubmissions(false)
        }
    }

    const onDownload = (url) => {
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const onMultiDownload = (o) => {
        for (const material of o.medias) {
            onDownload(material.url)
        }
    }

    return (
        <Card style={{ padding: 0 }}>
            {
                !loading && _exercise &&
                < div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.teacher} onClick={() => navigate(`/users/teachers/${_exercise.teacher.id}`)}>
                            <img className={styles.picture} src={picture} alt="" onError={() => setPicture(UserPlaceholder)} />
                            <div className={styles.teacherName}>{_exercise.teacher.name} {_exercise.teacher.surname}</div>
                        </div>
                        <div className={styles.left}>
                            <div className={styles.name}>
                                {_exercise.name}
                            </div>
                            <TruncatedText text={_exercise.description} maxLength={32} />
                        </div>
                        <div className={styles.info}>
                            <div className={styles.labeled}>
                                <div className={styles.value} style={{ maxWidth: "320px" }}>
                                    {`${_exercise.course.name} - ${_exercise.edition.name}`} - {_exercise.module.name}
                                    {
                                        _exercise.lesson &&
                                        ` - ${_exercise.lesson?.name ?? "Nessuna lezione"}`
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={styles.labeled}>
                            <div className={styles.label}>Materiali</div>
                            <Button
                                appearance="text"
                                onClick={() => setMaterialExpanded(e => !e)}
                                disabled={materialsNumber === 0}
                            >
                                {materialsNumber}
                            </Button>
                        </div>
                        <Button
                            appearance="text"
                            onClick={onClick}
                        >
                            VEDI CONSEGNE
                        </Button>
                        <MeatBallsMenu actions={[{ label: "Elimina", icon: DeleteIcon, onClick: () => onDelete(), style: { color: "var(--sf-red)" } }]} />

                    </div>
                    <SimpleCollapsable expanded={materialExpanded}>
                        <div>
                            <div className={styles.divider} />
                            <div className={styles.materials}>
                                <Each
                                    of={_exercise.medias}
                                    render={(material) => (
                                        <div className={styles.material}>
                                            <div>{material.name}</div>
                                            <div>{formatFileSize(material.size)}</div>
                                            <div className={styles.download} onClick={() => onDownload(material.url)}>
                                                <DownloadIcon style={{ width: "18px" }} />
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </SimpleCollapsable>
                    <SimpleCollapsable expanded={expanded}>
                        <div>
                            <div className={styles.divider} />
                            <div className={styles.users}>
                                {
                                    loadingSubmissions &&
                                    <div className={styles.loader}>
                                        <Loader />
                                    </div>
                                }
                                {
                                    !loadingSubmissions &&
                                    <Each
                                        of={_exercise.submissions}
                                        render={(submit) => (
                                            <div className={styles.user}>
                                                <div className={styles.teacher} onClick={() => navigate(`/users/students/${submit.user.id}`)}>
                                                    <img className={styles.picture} src={submit.user.picture ?? UserPlaceholder} alt="" />
                                                    <div className={styles.teacherName}>{submit.user.name} {submit.user.surname}</div>
                                                    <div className={styles.end}>
                                                        <div className={styles.time}>
                                                            {formatTimeV2(submit.created_at)}, {formatDateV2(submit.created_at)} <ClockIcon className={styles.icon} />
                                                        </div>
                                                        <Button
                                                            style={{ display: "flex", alignItems: "center", position: "relative", gap: "4px" }}
                                                            appearance="text"
                                                            onClick={() => onMultiDownload(submit)}>
                                                            CONSEGNA
                                                            <DownloadIcon style={{ width: "18px" }} />
                                                            {submit.medias.length > 1 && <span className={styles.counter}>{submit.medias.length}</span>}

                                                        </Button>
                                                        {
                                                            submit.correction &&
                                                            <Button

                                                                style={{ display: "flex", alignItems: "center", position: "relative", gap: "4px" }}
                                                                accentColor={"var(--secondary)"}
                                                                appearance="text"
                                                                onClick={() => {
                                                                    onMultiDownload(submit.correction)
                                                                }}>
                                                                <div data-tooltip-id="tooltip" data-tooltip-content={submit.correction.message ?? "Nessun commento"}>
                                                                    CORREZIONE
                                                                </div>
                                                                <div className={styles.download} style={{ color: "var(--secondary)" }}>
                                                                    <DownloadIcon style={{ width: "18px" }} />
                                                                </div>
                                                                {submit.correction.medias.length > 1 && <span className={styles.counter}>{submit.correction.medias.length}</span>}
                                                            </Button>
                                                        }
                                                        <Tooltip id="tooltip"
                                                            multiline={true}
                                                            style={{
                                                                whiteSpace: "normal",
                                                                maxWidth: "512px",
                                                                zIndex: 9999,
                                                                borderRadius: "16px",
                                                                color: "#000",
                                                                background: '#fff',
                                                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    />
                                }
                            </div>
                        </div>
                    </SimpleCollapsable>
                </div>
            }

            {
                loading &&
                <div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.teacher}>
                            <Skeleton width="48px" height="48px" type="circle" />
                        </div>
                        <div className={styles.left}>
                            <div className={styles.name}>
                                <Skeleton width="148px" height="1rem" type="rect" borderRadius={"8px"} />
                            </div>
                        </div>
                        <div className={styles.info}>
                            <div className={styles.labeled}>
                                <div className={styles.value} style={{ maxWidth: "320px" }}>
                                    <Skeleton width="164px" height="1rem" type="rect" borderRadius={"8px"} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.labeled}>
                            <Skeleton width="32px" height="32px" type="rect" borderRadius={"8px"} />
                        </div>
                        <Skeleton width="32px" height="32px" type="rect" borderRadius={"8px"} />
                    </div>
                </div>
            }
        </Card >
    )
}

export default ExerciseCard
