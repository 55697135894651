
import { format } from "date-fns";
import { PureComponent, useMemo } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { TimeGroup } from "../../common/constants";
import styles from "./CourseProfitChart.module.css"
import { fillEmptyDays, fillEmptyMonths } from "../../utils";
class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload, totalTicks } = this.props;

        const isDay = payload.value.split("/").length > 1
        const rotate = isDay ? "rotate(-45)" : "rotate(0)"

        const result = (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform={rotate}
                    style={{ fontSize: "0.75rem" }}
                >
                    {payload.value}
                </text>
            </g>
        )
        return result
    }
}

const CourseProfitChart = ({ data = [], group = TimeGroup.Daily, loading = false }) => {

    const y = useMemo(() => {
        let result = []
        result = [...data]
        result.sort((a, b) => a.purchase_date - b.purchase_date)

        for (let i = 0; i < result.length; i++) {
            const { purchase_date, amount } = result[i]
            if (i === 0) {
                result[i] = { date: new Date(purchase_date).toISOString().split("T")[0], amount, }
            } else {
                const p = result[i - 1]
                result[i] = { date: new Date(purchase_date).toISOString().split("T")[0], amount: amount + p.amount }
            }
        }

        result = group === TimeGroup.Daily ? fillEmptyDays(result) : fillEmptyMonths(result)
        result = result.map(r => {
            return {
                ...r,
                date: format(new Date(r.date), group === TimeGroup.Daily ? "dd/MM/yy" : "MM/yy")
            }
        })


        return result
    }, [data])

    return (
        <ResponsiveContainer width={"99%"} height={320}>
            {
                loading &&
                <div className={styles.loader}>
                    <div className={"loader"} style={{ width: "64px", height: "64px", position: "absolute", zIndex: 1001 }} />
                </div>
            }
            <LineChart data={y}
                margin={{ top: 5, right: 30, left: 5, bottom: 24 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={"date"} tick={<CustomizedAxisTick totalTicks={y.length} />} angle={-45} />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign={"bottom"} wrapperStyle={{ position: "absolute", bottom: "0" }} />
                <Line type="monotone" dataKey={"amount"} name={"Profitto"} stroke="var(--primary)" />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default CourseProfitChart
