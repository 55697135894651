import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import { ReactComponent as EmailIcon } from "../../assets/images/icons/ic-email.svg"
import { ReactComponent as WhatsappIcon } from "../../assets/images/icons/ic-whatsapp.svg"
import { ReactComponent as PhoneIcon } from "../../assets/images/icons/ic-phone.svg"
import { BookingMethod, BookingStatus } from "../../common/constants"
import typo from "../../typography.module.css"
import { formatDate, formatDateV2, formatTimeV2, UTCtoLocal } from "../../utils"
import StatusBadge from "../StatusBadge"
import styles from "./BookingCard.module.css"
import Card from "./Card"
import Button from "../Button"
import api from "../../api"
import SimpleCollapsable from "../SimpleCollapsable"
import TextInput from "../TextInput"

const BookingCard = ({ booking = null, onClaim = () => { }, onUpdate = () => { } }) => {

  const navigate = useNavigate()
  const [picture, setPicture] = useState(booking?.user.picture)
  const [date, setDate] = useState(null)
  const [url, setUrl] = useState("")
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (booking) {
      setPicture(booking.user.picture)
      setDate(booking.date ?? null)
      setUrl(booking.url)
      setExpanded(false)
    }
  }, [booking])

  const preference = useMemo(() => {
    if (!booking) {
      return null
    }

    const split = booking.preference.split(" - ")
    const date = formatDateV2(split[0], { month: 'long', day: 'numeric', year: "numeric" })
    const startTime = split[1].split("(")[1].replace("(", "")
    const endTime = split[2].replace(")", "")

    return {
      date,
      startTime,
      endTime,
    }
  }, [booking])

  const status = useMemo(() => {
    if (!booking) {
      return null;
    }

    const { status } = booking
    switch (status) {
      case BookingStatus.Pending:
        if (booking.admin_id) {
          return {
            label: "Da programmare",
            color: "var(--tertiary)"
          }
        } else {
          return {
            label: "In attesa",
            color: "var(--sf-gray)"
          }
        }
      case BookingStatus.Confirmed:
        if (UTCtoLocal(booking.date).getTime() < Date.now()) {
          return {
            label: "Scaduto",
            color: "var(--sf-red)"
          }
        } else {
          return {
            label: "Programmato",
            color: "var(--sf-yellow)"
          }
        }

      case BookingStatus.Completed:
        return {
          label: "Completato",
          color: "var(--sf-light-green)"
        }
      default: return null
    }
  }, [booking])

  const claim = async () => {
    try {
      await api.put(`/admin/bookings/${booking.id}`)
      onClaim()
    } catch (e) {
      console.error(e)
    }
  }

  const save = async () => {
    try {
      await api.put(`/admin/bookings/${booking.id}`, {
        date,
        url,
      })
      onUpdate()
    } catch (e) {
      console.error(e)
    }
  }

  const endMeeting = async () => {
    try {
      await api.put(`/admin/bookings/${booking.id}`, {
        status: BookingStatus.Completed,
      })
      onUpdate()
    } catch (e) {
      console.error(e)
    }
  }

  const handleContact = () => {
    switch (booking?.method) {
      case BookingMethod.Email:
        window.open(`mailto:${booking.contact}`)
        break
      case BookingMethod.Whatsapp:
        const phoneNumber = booking.contact.replace(/[^0-9]/g, '')
        window.open(`https://wa.me/${phoneNumber}`)
        break
      case BookingMethod.Phone:
        window.open(`tel:${booking.contact}`)
        break
      default: break
    }
  }

  return (
    <Card>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.student}
            onClick={handleContact}
          >
            <img className={styles.picture} src={picture} alt="" onError={() => setPicture(UserPlaceholder)} />
            <div className={styles.info}>
              <div className={styles.name}>{booking.user.name} {booking.user.surname}</div>
              <div className={styles.contact}
              >
                {booking.method === BookingMethod.Email && <EmailIcon />}
                {booking.method === BookingMethod.Whatsapp && <WhatsappIcon style={{
                  width: "20px",
                  height: "20px"
                }} />}
                {booking.method === BookingMethod.Phone && <PhoneIcon />}
                {booking.contact}
              </div>
            </div>
          </div>
          {
            booking.status === BookingStatus.Pending &&
            <div className={styles.preference}>
              <div className={styles.date}> {preference.date} </div>
              <div className={styles.time}> {preference.startTime} - {preference.endTime} </div>
            </div>
          }
          {
            !(booking.status === BookingStatus.Pending) &&
            <>
              <div className={styles.scheduled}>
                <div className={styles.date}> {formatDateV2(booking.date, { month: 'long', day: 'numeric', year: "numeric" })}, {formatTimeV2(booking.date)} </div>
              </div>
              {
                booking.url &&
                <Button
                  appearance="text"
                  disabled={booking.status === BookingStatus.Completed}
                  onClick={() => window.open(booking.url, "_blank")}>
                  Vai al meeting
                </Button>
              }
            </>
          }

          <div className={styles.actions}>
            {
              booking.status === BookingStatus.Pending && !booking.admin_id &&
              <Button
                appearance="text"
                onClick={claim}
              >
                Assegna a me
              </Button>
            }
            {
              !(booking.status === BookingStatus.Pending && !booking.admin_id) &&
              booking.status !== BookingStatus.Completed &&
              <Button
                appearance="text"
                onClick={() => setExpanded(e => !e)}
              >
                {!expanded ? "Aggiorna" : "Nascondi"}
              </Button>
            }
          </div>
          <div className={styles.status}>
            <div className={typo.caption}>{UTCtoLocal(booking.updated_at ?? booking.created_at).toLocaleString("it-IT")}</div>
            <StatusBadge {...status} />
          </div>
        </div>

        {
          !(booking.status === BookingStatus.Pending && !booking.admin_id) &&
          booking.status !== BookingStatus.Completed &&
          <SimpleCollapsable expanded={expanded}>
            <div className={styles.form}>
              <div className={styles.formDate}>
                <TextInput
                  type="date"
                  value={date}
                  defaultValue={booking.date ? new Date(booking.date) : null}
                  placeholder="Data"
                  onKeyUp={(d) => {
                    setDate(d ? d.toISOString() : null)
                  }}
                />
              </div>

              <TextInput
                type="text"
                value={url}
                placeholder={"Url meeting"}
                onKeyUp={(v) => setUrl(v)}
              />
              <Button
                style={{ padding: ".75rem 1.5rem" }}
                onClick={save}
                disabled={!url || !date}
              >
                SALVA
              </Button>
              {
                booking.status === BookingStatus.Confirmed &&
                <Button
                  inverse
                  style={{ padding: ".75rem 1.5rem" }}
                  onClick={endMeeting}
                  disabled={!url || !date}
                >
                  SEGNA COME COMPLETATO
                </Button>
              }
            </div>

          </SimpleCollapsable>
        }
      </div>
    </Card>
  )

}

export default BookingCard
