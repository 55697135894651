import { useContext, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import MainContext from "../common/MainContext";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import typo from "../typography.module.css";
import styles from "./Bookings.module.css";
import BookingsCard from "../components/cards/BookingsCard";

const Bookings = () => {
  const { t } = useTranslation();
  const context = useContext(MainContext)

  const ref = useRef(null)

  const onClaim = () => {
    ref.current?.getBookings()
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>
            Richieste di contatto
          </title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>Richieste di contatto</div>
            <BookingsCard ref={ref} />
            <BookingsCard pending onClaim={onClaim} />
          </div>
        </div>
      </div>
    </HeaderFooterLayout>

  );
};

export default Bookings;
