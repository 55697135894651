import { forwardRef, useEffect } from "react";
import styles from "./ContextMenu.module.css"
import { Each } from "../common/Each";

// Componente che utilizza il context menu
const ContextMenu = forwardRef(({ position, actions }, ref) => {

  return (
    <div className={styles.container} ref={ref} style={{ top: position.y, left: position.x, zIndex: 9999 }}>
      <Each of={actions} render={(action) => {
        return (
          <div className={styles.action} style={{ color: action.color }} onClick={action.onClick}>
            {action.icon}
            <div className={styles.actionName}>{action.name}</div>
          </div>
        )
      }} />
    </div>
  )
});

export default ContextMenu;