import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth"
import { useContext, useEffect, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { useTranslation } from "react-i18next"
import TextareaAutosize from 'react-textarea-autosize'
import api from "../api"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-image-edit.svg"
import { ReactComponent as InsertImageIllustration } from "../assets/images/illustrations/il-insert-image.svg"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png"
import { ButtonStatus } from "../common/constants"
import MainContext from "../common/MainContext"
import ImageCropper from "../components/ImageCropper"
import { auth } from "../firebase"
import typo from "../typography.module.css"
import { capitalize } from "../utils"
import Back from "./Back"
import Button from "./Button"
import Dialog from "./dialogs/Dialog"
import DropdownMultiSelection from "./DropdownMultiSelection"
import styles from "./EditProfile.module.css"
import Tag from "./Tag"
import TextInput from "./TextInput"

const EditProfile = ({ onBack = () => { }, tagsList = [] }) => {
  const { t } = useTranslation();
  const context = useContext(MainContext)

  const [profilePic, setProfilePic] = useState(null)
  const [name, setName] = useState(context.user?.name ?? '')
  const [surname, setSurname] = useState(context.user?.surname ?? '')
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [repeatNewPassword, setRepeatNewPassword] = useState("")
  const [error, setError] = useState("")
  const [hasChanges, setHasChanges] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [imgFile, setImgFile] = useState(null)
  const [cropMode, setCropMode] = useState(false)
  const [alert, setAlert] = useState(null)

  const [savingStatus, setSavingStatus] = useState(null)

  const dropzoneRef = useRef(null)

  useEffect(() => {
    setError(
      newPassword && repeatNewPassword && newPassword !== repeatNewPassword ?
        t('errors.passwordNotEquals') : ""
    )
  }, [newPassword, repeatNewPassword])

  useEffect(() => {
    if (context.user) {
      setHasChanges(
        (name !== context.user.name) ||
        (surname !== context.user.surname) ||
        (newPassword !== '' && repeatNewPassword !== '' && newPassword === repeatNewPassword) ||
        ((profilePic && profilePic !== context.user.profilePic) ?? false)
      )
    }
  }, [context.user, name, surname, profilePic, newPassword, repeatNewPassword])

  const saveChanges = async () => {
    setSavingStatus(ButtonStatus.Loading)
    try {
      const formData = new FormData()
      formData.append("name", name)
      formData.append("surname", surname)

      if (profilePic) {
        let blob = await fetch(profilePic).then(r => r.blob());
        formData.append("picture", blob, `${context.user.id}-picture.jpg`);
      }

      try {
        const user = await api.put("/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        if (user) {

          context.setUser(user)
          setProfilePic(null)
        }
      } catch (e) {
        throw new Error("Non è stato possibile salvare le modifiche.")
      }

      if (newPassword !== '' && repeatNewPassword !== '' && (newPassword === repeatNewPassword)) {
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          oldPassword
        )
        try {
          await reauthenticateWithCredential(auth.currentUser, credential)
        }
        catch (error) {
          throw new Error('Autenticazione fallita.')
        }
        await updateUserPassword()
      }
      setOldPassword('')
      setNewPassword('')
      setRepeatNewPassword('')
      setDialogOpen(true)
      setAlert({ title: t('profile.saved'), message: t('profile.changesSaved') })
      setSavingStatus(ButtonStatus.Success)
    } catch (e) {
      console.error(e)
      setError(e.message)
      setSavingStatus(ButtonStatus.Error)
    }
  }

  const updateUserPassword = async () => {
    try {
      await updatePassword(auth.currentUser, newPassword)
    }
    catch (error) {
      setError(error.message)
    }
  }


  return (
    <div className={styles.container}>
      <Back onClick={onBack} />
      <div className={styles.content}>
        <div className={styles.leftColumn}>
          <div className={styles.admin}>
            <img className={styles.profilePic} src={(profilePic || context.user?.picture) ?? UserPlaceholder} alt='' />
            <div className={styles.username}>{`${context.user?.name} ${context.user?.surname}`}</div>
            <Button
              accentColor={"var(--tertiary)"}
              onClick={() => {
                setDialogOpen(true)
              }}
            >
              <EditIcon />
              {t("profile.edit").toUpperCase()}
            </Button>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>{t("profile.editTitle")}</div>
            <div className={styles.inputs}>
              <div className={styles.row}>
                <TextInput value={context.user?.name} type="text" placeholder={capitalize(t('profile.name'))} onKeyUp={v => setName(v)}></TextInput>
                <TextInput value={context.user?.surname} type="text" placeholder={capitalize(t('profile.surname'))} onKeyUp={v => setSurname(v)}></TextInput>
              </div>
            </div>
            <div className={styles.cardTitle}>{t("profile.changePassword")}</div>
            <div className={styles.inputs}>
              <TextInput value={oldPassword} type="password" placeholder={capitalize(t('profile.oldPassword'))} onKeyUp={v => setOldPassword(v)} />
              <TextInput value={newPassword} type="password" placeholder={capitalize(t('profile.newPassword'))} onKeyUp={v => setNewPassword(v)} />
              <TextInput value={repeatNewPassword} type="password" placeholder={capitalize(t('profile.repeatNewPassword'))} onKeyUp={v => setRepeatNewPassword(v)} />
              {
                error &&
                <span className={typo.caption} style={{ color: "var(--secondary)" }}>
                  {error}
                </span>
              }
            </div>
            <div style={{ alignSelf: "flex-end" }}>
              <Button
                status={savingStatus}
                accentColor={"var(--primary)"}
                style={{ padding: "0.8rem 1.5rem" }}
                disabled={hasChanges === false}
                onClick={saveChanges}
              >
                {t("profile.saveChanges").toUpperCase()}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        style={{
          width: "30vw",
          maxWidth: "512px"
        }}
        onClose={() => {
          setDialogOpen(false)
          setCropMode(false)
          setAlert(null)
          if (alert) {
            onBack()
          }
        }}
        title={alert ? alert.title : "Immagine del profilo"}>
        <div className={styles.dialog}>
          {!alert &&
            <>
              {!cropMode &&
                <Dropzone
                  onDrop={(files) => {
                    if (files[0]) {
                      setImgFile(files[0])
                      setCropMode(true)
                    }
                  }}
                  accept={{
                    'image/jpeg': [],
                    'image/png': [],
                    'image/heic': []
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section style={{ display: 'flex', width: '100%' }}>
                      <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                        <input {...getInputProps()} ref={dropzoneRef} />
                        <div className={styles.editPictureDropzone}>
                          <InsertImageIllustration></InsertImageIllustration>
                          <div className={styles.editPictureLabel}>
                            {t('profile.dropImage')}
                          </div>
                          <Button
                            style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                            accentColor={"var(--tertiary)"}
                            onClick={() => dropzoneRef.current?.click()}
                          >
                            {t('profile.chooseImage').toUpperCase()}
                          </Button>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              }
              {cropMode &&
                <>
                  <ImageCropper
                    src={URL.createObjectURL(imgFile)}
                    onSave={(image) => {
                      setProfilePic(image)
                      setCropMode(false)
                      setDialogOpen(false)
                    }}
                    onCancel={() => { setCropMode(false) }}
                    compression={{ quality: 0.7, maxWidth: 300, maxHeight: 300 }}
                  >
                  </ImageCropper>
                </>
              }
            </>
          }
          {alert &&
            <div className={styles.editPictureLabel} style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              {alert.message}
            </div>
          }
        </div>
      </Dialog>
    </div>
  )
}

export default EditProfile
