import { useCallback, useEffect, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import TextareaAutosize from 'react-textarea-autosize'
import api from "../api"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg"
import { ReactComponent as CorrectionsIcon } from "../assets/images/icons/ic-corrections.svg"
import { ReactComponent as HourglassIcon } from "../assets/images/icons/ic-duration.svg"
import { ReactComponent as ExerciseIcon } from "../assets/images/icons/ic-exercise-active.svg"
import { ReactComponent as MaterialsIcon } from "../assets/images/icons/ic-files-active.svg"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-image-edit.svg"
import { ReactComponent as StopIcon } from "../assets/images/icons/ic-stop.svg"
import { ReactComponent as StudentClockIcon } from "../assets/images/icons/ic-student-clock.svg"
import { ReactComponent as StudentIcon } from "../assets/images/icons/ic-student.svg"
import { ReactComponent as StudentsIcon } from "../assets/images/icons/ic-students.svg"
import { ReactComponent as TestIcon } from "../assets/images/icons/ic-test-active.svg"
import { ReactComponent as UnlockIcon } from "../assets/images/icons/ic-unlock.svg"
import { ReactComponent as VideoLessonIcon } from "../assets/images/icons/ic-video-lesson.svg"
import { ReactComponent as InsertImageIllustration } from "../assets/images/illustrations/il-insert-image.svg"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png"
import { AccountStatus, ButtonStatus } from "../common/constants"
import { Each } from "../common/Each"
import Back from "../components/Back"
import Button from "../components/Button"
import StatisticsCard from "../components/cards/StatisticCard"
import CircularProgress from "../components/CircularProgress"
import AlertDialog from "../components/dialogs/AlertDialog"
import Dialog from "../components/dialogs/Dialog"
import DropdownMultiSelection from "../components/DropdownMultiSelection"
import ImageCropper from "../components/ImageCropper"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Skeleton from "../components/Skeleton"
import Tag from "../components/Tag"
import TextInput from "../components/TextInput"
import { DialogStatus } from "../enums"
import { capitalize, secondsToHours } from "../utils"
import styles from "./Teacher.module.css"

const Teacher = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { teacherId, tutorId } = useParams()
  const id = teacherId ?? tutorId

  const [teacher, setTeacher] = useState(null)
  const [teacherLoading, setTeacherLoading] = useState(false)
  const [profilePic, setProfilePic] = useState(null)
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [qualification, setQualification] = useState("")
  const [bio, setBio] = useState("")
  const [tagsList, setTagList] = useState([])
  const [tags, setTags] = useState([])
  const [options, setOptions] = useState([])
  const [hasChanges, setHasChanges] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [imgFile, setImgFile] = useState(null)
  const [cropMode, setCropMode] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: "",
    status: DialogStatus.Default,
    actions: []
  })

  const [circularContent, setCircularContent] = useState({
    label: "0%"
  })
  const [circularData, setCircularData] = useState([
    {
      id: "total",
      value: null,
      rawValue: 0,
      color: 'var(--tertiary)',
      label: `${t("profile.statistics.lessons")} ${t("profile.statistics.total")}`
    },
    {
      id: "completed",
      value: 0,
      rawValue: 0,
      color: 'var(--sf-green)',
      label: `${t("profile.statistics.lessons")} ${t("profile.statistics.completed")}`
    },
    {
      id: "scheduled",
      value: 0,
      rawValue: 0,
      color: 'var(--sf-yellow)',
      label: `${t("profile.statistics.lessons")} ${t("profile.statistics.scheduled")}`
    },
    {
      id: "postponed",
      value: 0,
      rawValue: 0,
      color: '#A1AFBF',
      label: `${t("profile.statistics.lessons")} ${t("profile.statistics.postponed")}`
    },
  ])
  const [lessons, setLessons] = useState({
    n_lessons: 0,
    n_scheduled: 0,
    n_completed: 0,
    n_postponed: 0,
    duration: 0,
    n_current_month: 0,
    n_previous_month: 0
  })
  const [users, setUsers] = useState({ n_users: 0, n_users_in_progress: 0 })
  const [exercises, setExercises] = useState({
    n_exercises: 0,
    n_previous_month: 0,
    n_current_month: 0,
    n_corrections: 0,
    n_corrections_previous_month: 0,
    n_corrections_current_month: 0
  })
  const [tests, setTests] = useState({ n_tests: 0, n_previous_month: 0, n_current_month: 0 })
  const [materials, setMaterials] = useState({ n_materials: 0, n_previous_month: 0, n_current_month: 0 })


  const [savingStatus, setSavingStatus] = useState(null)

  const dropzoneRef = useRef(null)

  useEffect(() => {
    const getTeacher = async () => {
      setTeacherLoading(true)
      try {
        const teacher = await api.get(`/admin/teachers/${id}`)
        setTeacher(teacher)
        setTeacherLoading(false)
        return teacher
      } catch (e) {
        setTeacherLoading(false)
        console.error(e)
      }
    }

    const getTags = async (teacher) => {
      try {
        const tags = await api.get(`/admin/tags?type=general`)

        setTagList(tags)
        setOptions(tags.filter((t) => {
          return !(teacher.tags && teacher.tags.map(lt => lt.id).includes(t.id))
        }))
      } catch (e) {
        console.error(e)
      }
    }

    const getStatistics = async (route, setState) => {
      try {
        const response = await api.get(route)
        setState(response)
      } catch (e) {
        console.error(e)
      }
    }

    getTeacher().then((t) => {
      getTags(t)
    })

    getStatistics(`/admin/teachers/${id}/lessons/statistics`, setLessons)
    getStatistics(`/admin/teachers/${id}/users/statistics`, setUsers)
    getStatistics(`/admin/teachers/${id}/exercises/statistics`, setExercises)
    getStatistics(`/admin/teachers/${id}/tests/statistics`, setTests)
    getStatistics(`/admin/teachers/${id}/materials/statistics`, setMaterials)
  }, [])

  useEffect(() => {
    if (teacher) {
      setName(teacher.name)
      setSurname(teacher.surname)
      setQualification(teacher.qualification)
      setBio(teacher.description)
      setTags(teacher.tags)
    }
  }, [teacher])

  useEffect(() => {
    if (teacher) {
      setHasChanges(
        (name !== teacher.name) ||
        (surname !== teacher.surname) ||
        (qualification !== teacher.qualification) ||
        (bio !== teacher.description) ||
        (JSON.stringify(tags) !== JSON.stringify(teacher.tags)) ||
        ((profilePic && profilePic !== teacher.profilePic) ?? false)
      )
    }
  }, [teacher, name, surname, qualification, bio, tags, profilePic])

  useEffect(() => {
    const newOptions = tagsList.filter((tl) => {
      return tags.map(t => t.id).includes(tl.id) === false
    })
    setOptions(newOptions)
  }, [tags])

  useEffect(() => {

    if (lessons.n_lessons === 0) {
      return;
    }

    setCircularContent({
      label: `${Math.round(lessons.n_completed / lessons.n_lessons * 10000) / 100}%`,
      sublabel: "Completamento"
    })

    setCircularData([
      {
        id: "total",
        value: null,
        rawValue: lessons.n_lessons,
        color: 'var(--tertiary)',
        label: `${t("profile.statistics.lessons")} ${t("profile.statistics.total")}`
      },
      {
        id: "completed",
        value: Math.round(lessons.n_completed / lessons.n_lessons * 10000) / 100,
        rawValue: lessons.n_completed,
        color: 'var(--sf-green)',
        label: `${t("profile.statistics.lessons")} ${t("profile.statistics.completed")}`
      },
      {
        id: "scheduled",
        value: Math.round(lessons.n_scheduled / lessons.n_lessons * 10000) / 100,
        rawValue: lessons.n_scheduled,
        color: 'var(--sf-yellow)',
        label: `${t("profile.statistics.lessons")} ${t("profile.statistics.scheduled")}`
      },
      {
        id: "postponed",
        value: Math.round(lessons.n_postponed / lessons.n_lessons * 10000) / 100,
        rawValue: lessons.n_postponed,
        color: '#A1AFBF',
        label: `${t("profile.statistics.lessons")} ${t("profile.statistics.postponed")}`
      },
    ])
  }, [lessons])

  const saveChanges = async () => {
    setSavingStatus(ButtonStatus.Loading)
    try {
      const formData = new FormData()
      formData.append("name", name)
      formData.append("surname", surname)
      formData.append("qualification", qualification)
      formData.append("description", bio)
      formData.append("tags", JSON.stringify(tags.map(t => t.id)))

      if (profilePic) {
        let blob = await fetch(profilePic).then(r => r.blob());
        formData.append("picture", blob, `${teacher.id}-picture.jpg`);
      }

      try {
        const teacher = await api.put(`/admin/teachers/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        setTeacher(teacher)
      } catch (e) {
        throw new Error("Non è stato possibile salvare le modifiche.")
      }

      // setDialogOpen(true)
      setAlert({ open: true, title: t('profile.saved'), message: t('profile.changesSaved'), status: DialogStatus.Success })
      setSavingStatus(ButtonStatus.Success)
    } catch (e) {
      console.error(e)
      setSavingStatus(ButtonStatus.Error)
    }
  }

  const resetAlert = useCallback(() => {
    setAlert({
      open: false,
      title: "",
      message: "",
      status: DialogStatus.Default,
      actions: []
    })
  }, [])

  const changeAccountStatus = useCallback(async (status) => {
    setAlert(a => { return { ...a, status: DialogStatus.Loading } })

    try {
      const teacher = await api.patch(`/admin/teachers/${id}`, {
        status
      })
      setTeacher(teacher)
      setAlert(a => { return { ...a, status: DialogStatus.Success } })
    } catch (e) {
      console.error(e)
      setAlert(a => { return { ...a, status: DialogStatus.Error } })
    }
  }, [id])

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Docenti</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <Back onClick={() => navigate(-1)} />
            <div className={styles.content}>
              <div className={styles.leftColumn}>
                <div className={styles.teacher}>
                  <img className={styles.profilePic} src={(profilePic || teacher?.picture) ?? UserPlaceholder} alt='' />
                  {
                    teacherLoading &&
                    <Skeleton type="rect" width="164px" height="24px" borderRadius={"12px"} />
                  }
                  {
                    !teacherLoading &&
                    <div className={styles.bio}>
                      <div className={styles.username}>{`${teacher?.name} ${teacher?.surname}`}</div>
                      <div className={styles.email}>{teacher?.email}</div>
                    </div>

                  }
                  <Button
                    accentColor={"var(--tertiary)"}
                    onClick={() => {
                      setDialogOpen(true)
                    }}
                  >
                    <EditIcon />
                    {t("profile.edit").toUpperCase()}
                  </Button>
                  {
                    teacher?.status === AccountStatus.Active &&
                    <Button
                      accentColor={"var(--sf-red)"}
                      onClick={() => {
                        setAlert({
                          open: true,
                          title: "Sospensione docente",
                          message: "Il docente non potrà più accedere alla piattaforma docenti. Sei sicuro di voler procedere?",
                          status: DialogStatus.Default,
                          actions: [
                            {
                              label: "ANNULLA",
                              onClick: resetAlert,
                              inverse: true
                            },
                            {
                              label: "SI, SOSPENDI",
                              onClick: () => changeAccountStatus(AccountStatus.Suspended),
                            }
                          ]
                        })
                      }}
                    >
                      <StopIcon style={{ transform: "rotate(-45deg)" }} />
                      SOSPENDI
                    </Button>
                  }

                  {
                    teacher?.status === AccountStatus.Suspended &&
                    <Button
                      accentColor={"var(--primary)"}
                      onClick={() => {
                        setAlert({
                          open: true,
                          title: "Attivazione docente",
                          message: "Il docente potrà accedere alla piattaforma docenti. Sei sicuro di voler procedere?",
                          status: DialogStatus.Default,
                          actions: [
                            {
                              label: "ANNULLA",
                              onClick: resetAlert,
                              inverse: true
                            },
                            {
                              label: "SI, ATTIVA",
                              onClick: () => changeAccountStatus(AccountStatus.Active),
                            }
                          ]
                        })
                      }}
                    >
                      <UnlockIcon />
                      ATTIVA
                    </Button>
                  }

                </div>
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.card}>
                  <div className={styles.cardTitle}>{t("profile.editTitle")}</div>
                  <div className={styles.inputs}>
                    <div className={styles.row}>
                      <TextInput value={teacher?.name} type="text" placeholder={capitalize(t('profile.name'))} onKeyUp={v => setName(v)}></TextInput>
                      <TextInput value={teacher?.surname} type="text" placeholder={capitalize(t('profile.surname'))} onKeyUp={v => setSurname(v)}></TextInput>
                    </div>
                    <TextInput value={teacher?.qualification} type="text" placeholder={capitalize(t('profile.qualification'))} onKeyUp={v => setQualification(v)} />
                    <TextareaAutosize
                      value={bio}
                      minRows={3} maxRows={10}
                      type="text"
                      placeholder={capitalize(t("profile.bio"))}
                      className={styles.textArea}
                      onChange={e => setBio(e.target.value)} />

                    <DropdownMultiSelection
                      placeholder={capitalize(t("profile.skills"))}
                      options={options}
                      selectedOptions={tags}
                      onSelect={(newTags) => setTags(newTags)}
                      itemsRender={(item, index) => (
                        <Tag tag={item} Icon={tags.find(t => t.id === item.id) ? CloseIcon : null}
                          onClick={(e) => {
                            e.stopPropagation()
                            const idx = tags.findIndex(t => t.id === item.id)
                            if (idx >= 0) {
                              tags.splice(idx, 1)
                              setTags([...tags])
                            } else {
                              setTags([...tags, item])
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                  <div style={{ alignSelf: "flex-end" }}>
                    <Button
                      status={savingStatus}
                      accentColor={"var(--primary)"}
                      style={{ padding: "0.8rem 1.5rem" }}
                      disabled={hasChanges === false}
                      onClick={saveChanges}
                    >
                      {t("profile.saveChanges").toUpperCase()}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.statsContainer}>
              <div className={styles.card}>
                <div className={styles.header}>
                  <div className={styles.subtitle}>{t("teacher.statistics.title").toUpperCase()}</div>
                  <div className={styles.title}>{t("teacher.statistics.lessons")}</div>
                </div>
                <div className={styles.pieChartContainer}>
                  <div className={styles.chartBadges}>
                    <Each
                      of={circularData}
                      render={(d) => (
                        <div className={styles.chartBadge}>
                          <div className={styles.left}>
                            <div className={styles.chartColor} style={{ backgroundColor: d.color }} />
                            <div className={styles.chartLabel} style={{ color: d.color }}>{d.label}</div>
                          </div>
                          <div className={styles.chartValue}>{d.rawValue}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className={styles.pieChart}>
                    <CircularProgress content={circularContent} data={circularData.filter(d => d.id !== "total")} />
                  </div>
                </div>
                <div className={styles.innerCards}>
                  <StatisticsCard
                    Icon={VideoLessonIcon}
                    color="var(--sf-blue)"
                    label="Lezioni in questo mese"
                    value={lessons.n_current_month}
                    increment={lessons.n_current_month - lessons.n_previous_month}
                  />
                  <StatisticsCard
                    Icon={HourglassIcon}
                    color="var(--tertiary)"
                    label="Durata totale lezioni"
                    value={lessons.duration}
                    format={(v) => secondsToHours(v)}
                  />
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.header}>
                  <div className={styles.subtitle}>{t("teacher.statistics.title").toUpperCase()}</div>
                  <div className={styles.title}>{t("teacher.statistics.students")}</div>
                </div>
                <div className={styles.innerCards}>
                  <StatisticsCard
                    style={{ minWidth: "196px" }}
                    Icon={StudentsIcon}
                    color="var(--sf-purple)"
                    label={t("teacher.statistics.totalStudents")}
                    value={users.n_users}
                  />
                  <StatisticsCard
                    style={{ minWidth: "196px" }}
                    Icon={StudentIcon}
                    color="var(--sf-fucsia)"
                    label={t("teacher.statistics.currentStudents")}
                    value={users.n_users_in_progress}
                  />
                  <StatisticsCard
                    style={{ minWidth: "196px" }}
                    Icon={StudentClockIcon}
                    color="var(--sf-pink)"
                    label={t("teacher.statistics.averageSession")}
                    value={0}
                    format={(v) => secondsToHours(v)}
                  />
                </div>
              </div>
              <div className={styles.outerCards}>
                <StatisticsCard
                  Icon={ExerciseIcon}
                  color="var(--sf-yellow)"
                  label={t("teacher.statistics.requestedExercise")}
                  value={exercises.n_exercises}
                  increment={exercises.n_current_month - exercises.n_previous_month}
                />
                <StatisticsCard
                  Icon={TestIcon}
                  color="var(--tertiary)"
                  label={t("teacher.statistics.createdTests")}
                  value={tests.n_tests}
                  increment={tests.n_current_month - tests.n_previous_month}
                />
                <StatisticsCard
                  Icon={CorrectionsIcon}
                  color="var(--sf-aqua)"
                  label={t("teacher.statistics.corrections")}
                  value={exercises.n_corrections}
                  increment={exercises.n_corrections_current_month - exercises.n_corrections_previous_month}
                />
                <StatisticsCard
                  Icon={MaterialsIcon}
                  color="var(--sf-green)"
                  label={t("teacher.statistics.materials")}
                  value={materials.n_materials}
                  increment={materials.n_current_month - materials.n_previous_month}
                />
              </div>
            </div>


            <Dialog
              open={dialogOpen}
              style={{
                width: "30vw",
                maxWidth: "512px"
              }}
              onClose={() => {
                setDialogOpen(false)
                setCropMode(false)
              }}
              title={"Immagine del profilo"}>
              <div className={styles.dialog}>
                {!cropMode &&
                  <Dropzone
                    onDrop={(files) => {
                      if (files[0]) {
                        setImgFile(files[0])
                        setCropMode(true)
                      }
                    }}
                    accept={{
                      'image/jpeg': [],
                      'image/png': [],
                      'image/heic': []
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section style={{ display: 'flex', width: '100%' }}>
                        <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                          <input {...getInputProps()} ref={dropzoneRef} />
                          <div className={styles.editPictureDropzone}>
                            <InsertImageIllustration></InsertImageIllustration>
                            <div className={styles.editPictureLabel}>
                              {t('profile.dropImage')}
                            </div>
                            <Button
                              style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                              accentColor={"var(--tertiary)"}
                              onClick={() => dropzoneRef.current?.click()}
                            >
                              {t('profile.chooseImage').toUpperCase()}
                            </Button>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                }
                {cropMode &&
                  <>
                    <ImageCropper
                      src={URL.createObjectURL(imgFile)}
                      onSave={(image) => {
                        setProfilePic(image)
                        setCropMode(false)
                        setDialogOpen(false)
                      }}
                      onCancel={() => { setCropMode(false) }}
                      compression={{ quality: 0.7, maxWidth: 300, maxHeight: 300 }}
                    >
                    </ImageCropper>
                  </>
                }
                {/* {alert &&
                  <div className={styles.editPictureLabel} style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    {alert.message}
                  </div>
                } */}
              </div>
            </Dialog>

            <AlertDialog
              open={alert.open}
              title={alert.title}
              text={alert.message}
              onClose={alert.onClose ?? resetAlert}
              actions={alert.actions}
              status={alert.status}
            />
          </div>
        </div>
      </div>
    </HeaderFooterLayout >
  )
}

export default Teacher
