import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./Careers.module.css"
import typo from "../typography.module.css"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import api from "../api"
import Card from "../components/cards/Card"
import { Each } from "../common/Each"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { useNavigate } from "react-router-dom"
import Skeleton from "../components/Skeleton"
import Button from "../components/Button"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import useContextMenu from "../common/hooks/useContextMenu"
import { ReactComponent as CopyIcon } from "../assets/images/icons/ic-copy.svg"
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg"
import AlertDialog from "../components/dialogs/AlertDialog"

const Careers = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const [careers, setCareers] = useState(null)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState({ open: false, title: '', text: '' })

    const { showMenu } = useContextMenu();

    useEffect(() => {
        const getCareers = async () => {
            setLoading(true)
            try {
                let careers = await api.get("/admin/careers")
                setCareers(careers.sort((a, b) => { return a.name > b.name }))
            }
            catch (e) {
                console.error(e)
            }
            setLoading(false)
        }

        if (!careers) {
            getCareers()
        }
    }, [])

    const getStatusStyle = (status) => {
        switch (status) {
            case 'public':
                return styles.public
            case 'draft':
                return styles.draft
            case 'hidden':
                return styles.hidden
            default:
                return null
        }
    }

    const duplicate = async (careerId) => {
        try {
            let career = await api.get(`/admin/careers/${careerId}/duplicate`)
            setCareers([...careers, career])
        }
        catch (e) {
            console.error(e)
        }
    }

    const deleteCareer = async (careerId) => {
        try {
            await api.delete(`/admin/careers/${careerId}`)
            setCareers(careers.filter(c => c.id !== careerId))
        }
        catch (e) {
            console.error(e)
        }
    }


    return (
        <HeaderFooterLayout>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {t('careers.title')}
                            <Button style={{ padding: '0 1rem' }} onClick={() => { navigate('/careers/create') }}>
                                AGGIUNGI
                                <AddIcon />
                            </Button></div>

                        <Card>
                            <div className={styles.careers}>
                                {loading &&
                                    <Each of={Array(7).fill('')} render={(i) => {
                                        return (
                                            <div className={styles.skeletonCareer}>
                                                <Skeleton type="rect" width={50} height={50} borderRadius="12px" />
                                                <div className={styles.skeletonInfo}>
                                                    <Skeleton type="rect" width={150} height={20} borderRadius="4px" />
                                                    <Skeleton type="rect" width={300} height={15} borderRadius="4px" />
                                                </div>
                                                <Skeleton type="rect" width={60} height={30} borderRadius="8px" />
                                                <ChevronIcon className={styles.chevron} />
                                            </div>
                                        )
                                    }} />
                                }
                                {!loading &&
                                    <Each of={careers} render={(career) => {
                                        return (
                                            <div className={styles.career}
                                                onContextMenu={(event) => {
                                                    let contextActions = [
                                                        {
                                                            name: 'Duplica',
                                                            icon: <CopyIcon />,
                                                            onClick: async () => {
                                                                await duplicate(career.id)
                                                            }
                                                        }
                                                    ]
                                                    if (career.status === 'draft') {
                                                        contextActions.push({
                                                            name: "Elimina",
                                                            icon: <DeleteIcon />,
                                                            onClick: () => {
                                                                setAlert({
                                                                    open: true,
                                                                    title: `Eliminare ${career.name}?`,
                                                                    text: 'L\'operazione non potrà essere annullata',
                                                                    actions: [
                                                                        {
                                                                            label: 'ELIMINA',
                                                                            onClick: async () => {
                                                                                await deleteCareer(career.id)
                                                                                setAlert((prev) => {
                                                                                    prev.open = false
                                                                                    return { ...prev }
                                                                                })
                                                                            },
                                                                            color: 'var(--sf-red)'
                                                                        }
                                                                    ]
                                                                })
                                                            },
                                                            color: 'var(--sf-red)'
                                                        })
                                                    }
                                                    showMenu(event, contextActions)
                                                }}
                                                onClick={() => {
                                                    navigate(`/careers/${career.id}`)
                                                }}>
                                                <img src={career.thumbnail} className={styles.thumbnail} alt="" />
                                                <div className={styles.info}>
                                                    <div className={styles.title}>{career.name}</div>
                                                    <div className={styles.description}>{career.description}</div>
                                                </div>
                                                <div className={`${styles.status} ${getStatusStyle(career.status)}`}>{career.status}</div>
                                                <ChevronIcon className={styles.chevron} />
                                            </div>
                                        )
                                    }} />
                                }
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <AlertDialog open={alert.open} title={alert.title} text={alert.text} actions={alert.actions} onClose={() => {
                setAlert((prev) => {
                    prev.open = false
                    return { ...prev }
                })
            }} />
        </HeaderFooterLayout>
    )

}

export default Careers
