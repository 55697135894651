import { Tooltip } from 'react-tooltip'

const TruncatedText = ({ text = "", maxLength = 20, style = {}, className = "" }) => {
    const isTruncated = text?.length > maxLength;
    return (
        <>
            {
                !isTruncated &&
                <div className={className} style={{ ...style }}>{text}</div>
            }
            {
                isTruncated &&
                <>
                    <div data-tooltip-id="tooltip" data-tooltip-content={text} className={className} style={{ ...style }}>{text.slice(0, maxLength) + '...'}</div>
                    <Tooltip id="tooltip"
                        multiline={true}
                        style={{
                            whiteSpace: "normal",
                            maxWidth: "512px",
                            zIndex: 9999,
                            borderRadius: "16px",
                            color: "#000",
                            background: '#fff',
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <div className={className} style={{
                            ...style,

                        }}>{text}</div>
                    </Tooltip>
                </>
            }
        </>
    );
};

export default TruncatedText;
