import { useMemo, useState } from "react"
import styles from "./TabItem.module.css"

const TabItem = ({
  IconComponent = null,
  text = "",
  value = "",
  color,
  selected = false,
  onClick = () => { },
  style = {},
  defaultBackground = "var(--background-color)",
  interactive = true,
}) => {

  const [isHover, setIsHover] = useState(false)

  const innerStyle = useMemo(() => {
    if (isHover && !selected) {
      return {
        color: `rgba(${color}, 1)`,
        backgroundColor: `rgba(${color}, 0.2)`
      }
    } else if (selected) {
      return {
        color: defaultBackground,
        backgroundColor: `rgba(${color}, 0.8)`
      }
    }
    return {
      color: `rgba(${color}, 1)`,
      backgroundColor: defaultBackground
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHover, selected])

  return (
    <div
      onClick={onClick}
      className={styles.container}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{ ...style, ...innerStyle, cursor: interactive ? "pointer" : "default" }}>
      {IconComponent && <IconComponent />}
      <div>{text}{value ? ` • ${value}` : ""}</div>
    </div>
  )
}

export default TabItem
