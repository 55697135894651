import { Children, cloneElement, useEffect, useRef, useState } from "react"
import styles from "./SimpleCollapsable.module.css"

const SimpleCollapsable = ({ children = <></>, expanded = false, expadendHeight, style = {} }) => {

    const [exp, setExp] = useState(expanded)
    const [height, setHeight] = useState(null)
    const [overflow, setOverflow] = useState("hidden")
    const expandableRef = useRef(null)

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (!expandableRef?.current) return;

            const { current } = expandableRef
            const boundingRect = current.getBoundingClientRect()
            const { height } = boundingRect
            setHeight(Math.round(height))
        });
        resizeObserver.observe(expandableRef.current);
        return () => resizeObserver.disconnect();
    }, [expandableRef])

    useEffect(() => {
        setExp(expanded ?? false)

        if (expanded === true) {
            setTimeout(() => setOverflow("visible"), 200)
        } else {
            setOverflow("hidden")
        }
    }, [expanded])

    return (
        <div
            style={{
                ...style,
                height: exp ? expadendHeight ?? `${height}px` : 0,
                overflow,
            }}
            className={styles.container}
        >
            {Children.map(children, (child, index) =>
                index === 0
                    ? cloneElement(child, { ref: expandableRef })
                    : child
            )}
        </div>
    )
}

export default SimpleCollapsable
