
import { format } from "date-fns";
import { PureComponent, useMemo } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { TimeGroup } from "../../common/constants";
import styles from "./OrdersChart.module.css"
import { fillEmptyDays, fillEmptyMonths } from "../../utils";
class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload, totalTicks } = this.props;

        const isDay = payload.value.split("/").length > 1
        const rotate = isDay ? "rotate(-45)" : "rotate(0)"

        const jump = Math.max(1, Math.floor(totalTicks / 15));
        const result = (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform={rotate}
                    style={{ fontSize: "0.625rem" }}
                >
                    {payload.value}
                </text>
            </g>
        )
        return result
    }
}

const OrdersChart = ({ data = [], group = TimeGroup.Daily, loading = false }) => {

    const y = useMemo(() => {
        let result = []
        const { total, succeeded } = data

        const missingKeys = (key) => {
            const keys = ['total', 'succeeded']
            const missing = []
            for (const k of keys) {
                if (key !== k) {
                    missing.push(k)
                }
            }
            return missing
        }

        const add = (date, key, value) => {
            const compactDate = date.split("T")[0]
            const idx = result.findIndex(dd => dd.date === compactDate)
            if (idx === -1) {
                const missing = missingKeys(key)
                result.push({ date: compactDate, [key]: value, [missing[0]]: 0, [missing[1]]: 0 })
            } else {
                result[idx][key] = value
            }
        }

        for (const b of succeeded) {
            add(b.order_date, "succeeded", b.count)
        }

        for (const t of total) {
            add(t.order_date, "total", t.count)
        }

        result.sort((a, b) => new Date(a.date) - new Date(b.date))

        for (let i = 1; i < result.length; i++) {
            const p = result[i - 1]
            const { date, total, succeeded } = result[i]
            result[i] = { date, total: total + p.total, succeeded: succeeded + p.succeeded }
        }

        result = group === TimeGroup.Daily ? fillEmptyDays(result) : fillEmptyMonths(result)
        result = result.map(r => {
            let f = "dd/MM/yy"
            if (group === TimeGroup.Monthly) {
                f = "MM/yy"
            }

            return {
                ...r,
                date: format(new Date(r.date), f)
            }
        })
        return result
    }, [data, group])

    return (
        <ResponsiveContainer width={"99%"} height={400}>
            {
                loading &&
                <div className={styles.loader}>
                    <div className={"loader"} style={{ width: "64px", height: "64px", position: "absolute", zIndex: 1001 }} />
                </div>
            }
            <LineChart data={y}
                margin={{ top: 5, right: 30, left: 5, bottom: 24 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={"date"} tick={<CustomizedAxisTick totalTicks={y.length} />} angle={-45} />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign={"bottom"} wrapperStyle={{ position: "absolute", bottom: "0" }} />
                <Line type="monotone" dataKey={"total"} name={"Ordini"} stroke="var(--primary)" />
                <Line type="monotone" dataKey={"succeeded"} name={"Completati"} stroke="var(--sf-orange)" />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default OrdersChart
