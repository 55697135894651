import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  headingsPlugin,
  InsertTable,
  linkDialogPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  quotePlugin,
  Separator,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { useEffect, useRef, useState } from 'react';
import Dropzone from "react-dropzone";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactPlayer from "react-player";
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api';
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg";
import { ReactComponent as UploadIcon } from "../assets/images/icons/ic-upload.svg";
import { ReactComponent as InsertDocumentIllustration } from "../assets/images/illustrations/il-insert-document.svg";
import { ReactComponent as PlayIcon } from "../assets/images/illustrations/il-play-button.svg";
import { ButtonStatus } from '../common/constants';
import Back from '../components/Back';
import Button from "../components/Button";
import Card from '../components/cards/Card';
import HeaderFooterLayout from '../components/layouts/HeaderFooterLayout';
import TagSelector from "../components/TagSelector";
import TextInput from "../components/TextInput";
import typo from "../typography.module.css";
import styles from "./VideoEditor.module.css";

const VideoEditor = ({ }) => {
  const { video_id } = useParams()
  const navigate = useNavigate()
  const [video, setVideo] = useState(null)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [slug, setSlug] = useState("")
  const [tags, setTags] = useState([])
  const [filteredTags, setFilteredTags] = useState([])
  const [tab, setTab] = useState(0)

  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailFile, setThumbnailFile] = useState(null)
  const [thumbnailPreview, setThumbnailPreview] = useState(null)
  const [isThumbnailUploaded, setIsThumbnailUploaded] = useState(false)

  const [url, setUrl] = useState("")
  const [videoPreview, setVideoPreview] = useState(null)
  const [videoFile, setVideoFile] = useState(null)
  const [isVideoUploaded, setIsVideoUploaded] = useState(false)


  const [publishing, setPublishing] = useState(null)
  const [uploadingVideo, setUploadingVideo] = useState(null)
  const [uploadingThumbnail, setUploadingThumbnail] = useState(null)


  const dropzoneRef = useRef(null)
  const mdRef = useRef(null)

  useEffect(() => {
    const getVideo = async () => {
      try {
        const video = await api.get(`/admin/videos/${video_id}`)
        setVideo(video)
      }
      catch (e) {
        console.error(e)
      }
    }

    if (video_id !== "create") {
      getVideo()
    }
  }, []);


  useEffect(() => {
    const getTags = async () => {
      try {
        const tags = await api.get("/admin/tags?type=general")
        setFilteredTags(tags)
      }
      catch (e) {
        console.error(e)
      }
    }

    getTags()
  }, [])

  useEffect(() => {
    if (video) {
      setTitle(video.title)
      setDescription(video.description)
      mdRef.current?.setMarkdown(video.description)
      setUrl(video.url)
      setThumbnail(video.thumbnail)
      setSlug(video.slug)
      setTags(video.tags)
    }

  }, [video])

  useEffect(() => {
    if (thumbnailFile) {
      setThumbnailPreview(URL.createObjectURL(thumbnailFile))
    } else {
      setThumbnailPreview(null)
    }
  }, [thumbnailFile])

  useEffect(() => {
    if (videoFile) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          // convert image file to base64 string
          setVideoPreview(reader.result);
        },
        false,
      );
      reader.readAsDataURL(videoFile);
    } else {
      setVideoPreview(null)
    }
  }, [videoFile])


  const uploadThumbnail = async () => {
    try {
      setUploadingThumbnail(ButtonStatus.Loading)

      const formData = new FormData()
      formData.append("file", thumbnailFile)
      const res = await api.post("/admin/upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
      setThumbnail(res.url)
      setThumbnailFile(null)
      setIsThumbnailUploaded(true)
      setUploadingThumbnail(ButtonStatus.Success)

    } catch (e) {
      console.error(e)
      setUploadingThumbnail(ButtonStatus.Error)
    }
  }
  const removeThumbnail = async () => {
    if (isThumbnailUploaded === false) {
      setThumbnailFile(null)
    } else {
      try {
        const res = await api.delete(`/admin/upload?url=${thumbnail}`)
        setThumbnail(null)
        setThumbnailFile(null)
        setIsThumbnailUploaded(false)
        setThumbnailPreview(null)
        setUploadingThumbnail(null)

      } catch (e) {
        console.error(e)
      }
    }
  }

  const uploadVideo = async () => {
    try {
      setUploadingVideo(ButtonStatus.Loading)
      const formData = new FormData()
      formData.append("file", videoFile)
      const res = await api.post("/admin/upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
      setUrl(res.url)
      setVideoFile(null)
      setIsVideoUploaded(true)
      setUploadingVideo(ButtonStatus.Success)

    } catch (e) {
      console.error(e)
      setUploadingVideo(ButtonStatus.Error)
    }
  }
  const removeVideo = async () => {
    if (isVideoUploaded === false) {
      setVideoFile(null)
    } else {
      try {
        const res = await api.delete(`/admin/upload?url=${url}`)
        setUrl(null)
        setVideoFile(null)
        setIsVideoUploaded(false)
        setVideoPreview(null)
        setUploadingVideo(null)
      } catch (e) {
        console.error(e)
      }
    }

  }

  const saveVideo = async () => {
    try {
      setPublishing(ButtonStatus.Loading)
      const newVideo = {
        title,
        slug,
        description,
        url,
        thumbnail,
        tags: JSON.stringify(tags.map(t => t.id)),
      }
      const res = video_id === "create" ?
        await api.post("/admin/videos", newVideo) :
        await api.put(`/admin/videos/${video_id}`, newVideo)

      setPublishing(ButtonStatus.Success)

      if (video_id === "create") {
        navigate(`/videos/${res.id}`)
      }
    } catch (e) {
      setPublishing(ButtonStatus.Error)

      console.error(e)
    }
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Editor video</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <Back onClick={() => navigate(-1)} />
            <div className={styles.header}>
              <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                {video_id === "create" ? "Aggiungi video" : "Modifica video"}
              </div>
            </div>
            <Card>
              <div className={styles.content}>
                <div className={styles.top}>
                  <div className={styles.left}>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>TITOLO</div>
                      <TextInput
                        type="text"
                        value={title}
                        placeholder={"Titolo"}
                        onKeyUp={(v) => {
                          setTitle(v)
                          const slug = v?.replace(/[^a-zA-Z0-9\s-]/g, '') // Rimuovi tutti i caratteri tranne lettere e numeri
                            .replace(/\s+/g, '-') // Sostituisci spazi con trattini
                            .toLowerCase()
                            .trim();
                          setSlug(slug)
                        }
                        }
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>SLUG</div>
                      <TextInput
                        type="text"
                        value={slug}
                        placeholder={"Slug"}
                        onKeyUp={(v) => setSlug(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>TAG</div>
                      <TagSelector
                        selected={tags}
                        options={filteredTags}
                        placeholder={(tags.length === 0 && "Seleziona un tag") || ""}
                        style={{ fontSize: "1rem" }}
                        onAdd={(item) => {
                          setTags(t => {
                            t.push(item)
                            return [...t]
                          })
                        }}
                        onRemove={(item) => {
                          setTags(t => {
                            t = t.filter((tt) => tt.id !== item.id)
                            return [...t]
                          })
                        }}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>{"URL THUMBNAIL"}</div>
                      <TextInput
                        type="text"
                        value={thumbnail}
                        placeholder={"Url thumbnail"}
                        onKeyUp={(v) => setThumbnail(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>{"URL VIDEO"}</div>
                      <TextInput
                        type="text"
                        value={url}
                        placeholder={"Url video"}
                        onKeyUp={(v) => setUrl(v)}
                      />
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.switch}>
                      <div
                        className={tab === 0 ? `${styles.tab}` : `${styles.tab} ${styles.notSelected}`}
                        onClick={() => setTab(0)}
                      >
                        Thumbnail
                      </div>
                      <div
                        className={tab === 1 ? `${styles.tab}` : `${styles.tab} ${styles.notSelected}`}
                        onClick={() => setTab(1)}
                      >
                        Video
                      </div>
                    </div>
                    <div className={styles.mediaContainer}>
                      {
                        tab === 0 && !thumbnailFile && !thumbnail &&
                        <Dropzone
                          accept={{
                            'image/jpeg': [],
                            'image/png': [],
                            'image/webp': [],
                            'image/heic': [],
                            'image/jfif': [],
                          }}
                          multiple={false}
                          onDrop={(files) => {
                            setThumbnailFile(files[0])
                          }}>
                          {({ getRootProps, getInputProps }) => (
                            <section style={{ display: 'flex', height: "100%", width: '100%', padding: 0, margin: 0 }}>
                              <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                                <input {...getInputProps()} />
                                <div className={styles.dropzone}>
                                  <InsertDocumentIllustration />
                                  <div className={styles.dropzoneLabel}>
                                    Trascina o seleziona {tab === 0 ? "l'immagine" : "il video"}
                                  </div>
                                  <Button
                                    style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                    accentColor={"var(--tertiary)"}
                                    onClick={() => dropzoneRef.current?.click()}
                                  >
                                    SELEZIONA
                                  </Button>
                                </div>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      }

                      {
                        tab === 0 && thumbnail && !isThumbnailUploaded &&
                        <div className={styles.thumbnailPreview}>
                          <img src={thumbnail} alt="thumbnail" />
                          <Button
                            style={{ padding: "0.75rem 1.5rem" }}
                            onClick={() => setThumbnail("")}
                            accentColor={"var(--sf-red)"}
                          >
                            ELIMINA
                            <DeleteIcon style={{ width: "16px", height: "16px" }} />
                          </Button>
                        </div>
                      }

                      {
                        tab === 0 && (thumbnailPreview || (thumbnail && isThumbnailUploaded)) &&
                        <div className={styles.thumbnailPreview}>
                          <img src={thumbnailPreview ?? thumbnail} alt="thumbnail" />
                          <div className={styles.action}>
                            <Button
                              disabled={isThumbnailUploaded}
                              style={{ padding: "0.75rem 1.5rem" }}
                              onClick={uploadThumbnail}
                              status={uploadingThumbnail}
                            >
                              CARICA
                              {!uploadingThumbnail && <UploadIcon style={{ width: "16px", height: "16px" }} />}
                            </Button>
                            <Button
                              disabled={!isThumbnailUploaded}
                              style={{ padding: "0.75rem 1.5rem" }}
                              onClick={removeThumbnail}
                              accentColor={"var(--sf-red)"}
                            >
                              ELIMINA
                              <DeleteIcon style={{ width: "16px", height: "16px" }} />
                            </Button>
                          </div>
                        </div>
                      }

                      {
                        tab === 1 && !videoFile && !url &&
                        <Dropzone
                          accept={{
                            'video/mp4': [],
                          }}
                          multiple={false}
                          onDrop={(files) => {
                            setVideoFile(files[0])
                          }}>
                          {({ getRootProps, getInputProps }) => (
                            <section style={{ display: 'flex', height: "100%", width: '100%', padding: 0, margin: 0 }}>
                              <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                                <input {...getInputProps()} />
                                <div className={styles.dropzone}>
                                  <InsertDocumentIllustration />
                                  <div className={styles.dropzoneLabel}>
                                    Trascina o seleziona {tab === 0 ? "l'immagine" : "il video"}
                                  </div>
                                  <Button
                                    style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                    accentColor={"var(--tertiary)"}
                                    onClick={() => dropzoneRef.current?.click()}
                                  >
                                    SELEZIONA
                                  </Button>
                                </div>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      }
                      {
                        tab === 1 && url && !isVideoUploaded &&
                        <div className={styles.videoPreview}>
                          <ReactPlayer
                            url={url}
                            controls
                            className={styles.player}
                            playIcon={<PlayIcon />}
                            config={
                              {
                                youtube: {
                                  playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 0 }
                                }
                              }
                            }
                          />
                          <Button
                            style={{ padding: "0.75rem 1.5rem" }}
                            onClick={() => setUrl("")}
                            accentColor={"var(--sf-red)"}
                          >
                            ELIMINA
                            <DeleteIcon style={{ width: "16px", height: "16px" }} />
                          </Button>
                        </div>
                      }
                      {
                        tab === 1 && (videoPreview || (url && isVideoUploaded)) &&
                        <div className={styles.videoPreview}>
                          <ReactPlayer
                            url={videoPreview ?? url}
                            controls
                            width="100%"
                            height="100%"
                            className={styles.player}
                            playIcon={<PlayIcon />}
                            config={
                              {
                                youtube: {
                                  playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 0 }
                                }
                              }
                            }
                          />
                          <div className={styles.action}>
                            <Button
                              disabled={isVideoUploaded}
                              style={{ padding: "0.75rem 1.5rem" }}
                              onClick={uploadVideo}
                              status={uploadingVideo}
                            >
                              CARICA
                              {!uploadingVideo && <UploadIcon style={{ width: "16px", height: "16px" }} />}

                            </Button>
                            <Button
                              style={{ padding: "0.75rem 1.5rem" }}
                              onClick={removeVideo}
                              accentColor={"var(--sf-red)"}
                            >
                              ELIMINA
                              <DeleteIcon style={{ width: "16px", height: "16px" }} />
                            </Button>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.editor}>
                  <MDXEditor
                    ref={mdRef}
                    contentEditableClassName={styles.markdownEditor}
                    markdown={description}
                    placeholder="Descrizione"
                    onChange={(value) => { setDescription(value) }}
                    plugins={[
                      headingsPlugin(),
                      listsPlugin(),
                      quotePlugin(),
                      thematicBreakPlugin(),
                      linkDialogPlugin(),
                      diffSourcePlugin(),
                      tablePlugin(),
                      toolbarPlugin({
                        toolbarContents: () => (
                          <>
                            {' '}
                            <UndoRedo />
                            <Separator />
                            <BoldItalicUnderlineToggles />
                            <Separator />
                            <ListsToggle />
                            <CreateLink />
                            <InsertTable />
                            <Separator />
                            <BlockTypeSelect />
                            <DiffSourceToggleWrapper options={["rich-text", "source"]} />
                          </>
                        )
                      })
                    ]}
                  />
                </div>
                <div className={styles.action}>
                  <Button
                    status={publishing}
                    disabled={!title || !slug || !url}
                    style={{ padding: "0.75rem 2rem" }}
                    onClick={saveVideo}
                  >
                    {video_id === "create" ? "PUBBLICA" : "SALVA"}
                  </Button>
                </div>
              </div>

            </Card>
          </div>
        </div>
      </div>
    </HeaderFooterLayout >
  )
}

export default VideoEditor
