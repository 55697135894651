import React, { useState, useEffect, useRef } from 'react';
import ContextMenu from '../../components/ContextMenu';
import { createRoot } from 'react-dom/client';
import ThemeProvider from "../../components/layouts/ThemeProvider"

// Hook personalizzato per gestire il context menu
function useContextMenu() {
    const contextMenuRef = useRef(null);

    const showMenu = (event, actions) => {
        event.preventDefault(); // Evita che il menu predefinito del browser venga mostrato

        let contextMenu = document.getElementById('contextMenu')
        if (contextMenu) {
            contextMenu.remove()
        }

        // Crea un nuovo div per il contesto
        const div = document.createElement('div');
        div.id = 'contextMenu'
        document.body.appendChild(div);

        // Crea la root e renderizza il ContextMenu
        const root = createRoot(div);
        root.render(
            <ThemeProvider theme={'starting-finance'}>
                <ContextMenu ref={contextMenuRef} position={{ x: event.pageX, y: event.pageY }} actions={actions} />
            </ThemeProvider>
        );

        // Salva la root per poterla smontare in seguito
        document.addEventListener('click', closeMenu);
    };

    const closeMenu = () => {
        let contextMenu = document.getElementById('contextMenu')
        if (contextMenu) {
            contextMenu.remove()
        }
        document.removeEventListener('click', closeMenu);
    };

    return { showMenu };
}

export default useContextMenu;
