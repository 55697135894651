import { GoogleAuthProvider, OAuthProvider, deleteUser, linkWithPopup, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import api from "../api";
import { ReactComponent as AppleLogo } from '../assets/images/apple.svg';
import { ReactComponent as GoogleLogo } from '../assets/images/google.svg';
import { ReactComponent as LogoBlack } from '../assets/images/velv-black.svg';
import MainContext from "../common/MainContext";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import FooterLayout from "../components/layouts/FooterLayout";
import { auth } from "../firebase";
import typo from "../typography.module.css";
import style from "./Login.module.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Login = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const context = useContext(MainContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null)
    const [resetPassword, setResetPassword] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [timer, setTimer] = useState(60)
    const [loading, setLoading] = useState(false)

    const googleProvider = new GoogleAuthProvider()
    googleProvider.setCustomParameters({
        // Localize the Apple authentication screen in Italian.
        locale: 'it'
    });

    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    appleProvider.setCustomParameters({
        // Localize the Apple authentication screen in Italian.
        locale: 'it'
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    useEffect(() => {
        if (auth.currentUser) {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.currentUser])

    useEffect(() => {
        async function unlockSend() {
            for (let i = 60; i > 0; i--) {
                setTimer(i)
                await new Promise((r) => {
                    setTimeout(r, 1000)
                })
            }
            setEmailSent(false)
        }

        if (emailSent) {
            unlockSend()
        }
    }, [emailSent])

    const googleSignIn = async () => {
        context.isLogging = true
        setLoading(true)
        try {
            await signInWithPopup(auth, googleProvider)
            let user = await api.get("/admin/")
            if (user) {
                context.setUser(user)
                navigate("/")
            }
        }
        catch (error) {
            await auth.signOut()
            setError({ code: -1, message: error.code === 3 ? "Nessun amministratore con queste credenziali" : error.detail })
            console.error("Error Google Sign In: ", error)
        }
        setLoading(false)
        context.isLogging = false
    }

    const appleSignIn = async () => {
        context.isLogging = true
        setLoading(true)
        try {
            await signInWithPopup(auth, appleProvider)
            let user = await api.get("/admin/")
            if (user) {
                context.setUser(user)
                navigate("/")
            }
        }
        catch (error) {
            await auth.signOut()
            setError({ code: -1, message: error.code === 3 ? "Nessun amministratore con queste credenziali" : error.detail })
            console.error("Error Apple Sign In: ", error)
        }
        setLoading(false)
        context.isLogging = false
    }

    const signIn = async () => {
        context.isLogging = true
        setLoading(true)
        try {
            await signInWithEmailAndPassword(auth, email, password)
            let user = await api.get("/admin/")
            if (user) {
                context.setUser(user)
                if (user.force_change_password) {
                    navigate("/changePassword")
                } else {
                    navigate("/")
                }
            }
        }
        catch (error) {
            await auth.signOut()
            setError({ code: -1, message: error.code === 3 ? "Nessun amministratore con queste credenziali" : error.detail })

            if (error.message.includes('invalid-credential')) {
                setError({ code: -1, message: t('errors.invalidCredentials') })
            }
            else {
                setError({ code: -1, message: error.message })
            }
        }
        setLoading(false)
        context.isLogging = false
    }

    const onEmailChange = (value) => {
        setEmail(value)
        if (!validateEmail(value)) {
            setError({ code: -1, message: t('errors.invalidEmail') })
        }
        else {
            setError(null)
        }
    }

    const onPasswordChange = (value) => {
        setPassword(value)
    }

    const validateEmail = (email) => {
        if (email && email.length > 0) {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        }
        else {
            return true
        }
    }

    const handleChangeResetPassword = () => {
        setResetPassword(!resetPassword)
    }

    const handleResetPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, email)
            setEmailSent(true)
        }
        catch (error) {
            setError({ code: -1, message: error.message })
        }
    }

    return (
        <FooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Login</title>
                </Helmet>
            </HelmetProvider>
            <div className={style.container}>
                {!auth.currentUser &&
                    <>
                        <div className={style.columnLeft}>
                            <div className={style.logoContainer}>
                                <LogoBlack onClick={() => { navigate("/") }} className={style.logo} />
                            </div>
                            <div className={style.innerLeft}>

                                <div className={style.spacer}></div>
                                {!resetPassword &&
                                    <>
                                        <div className={[typo.title, style.title].join(' ')}>{t('login.title')}</div>
                                        <div className={[typo.body, style.body].join(' ')}>{t('login.subtitle')}</div>

                                        <div className={style.formContainer}>
                                            <TextInput type="text" placeholder={t('login.email')} onKeyUp={onEmailChange}></TextInput>
                                            <TextInput type="password" placeholder={t('login.password')} onKeyUp={onPasswordChange}></TextInput>
                                            <div className={style.resetPassword}>

                                                <div className={style.error}>
                                                    {error &&
                                                        <span className={typo.caption}>
                                                            {error.message}
                                                        </span>
                                                    }
                                                </div>

                                                <Button
                                                    fullWidth
                                                    appearance="text"
                                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                                    onClick={handleChangeResetPassword}>
                                                    {t('login.forgotPassword')}
                                                </Button>
                                            </div>
                                        </div>

                                        <Button onClick={signIn} disabled={loading} fullWidth>LOGIN</Button>

                                        <div className={style.textDivider}>
                                            <div className={style.divider}></div>
                                            <div className={style.caption}>{t('login.or')}</div>
                                            <div className={style.divider}></div>
                                        </div>

                                        <div className={style.socialLogin}>
                                            <button className={style.googleSignIn} onClick={googleSignIn}>
                                                <GoogleLogo className={style.googleLogo}></GoogleLogo>
                                                {t('login.signInWithGoogle')}
                                            </button>
                                            <button className={style.appleSignIn} onClick={appleSignIn}>
                                                <AppleLogo className={style.appleLogo}></AppleLogo>
                                                {t('login.signInWithApple')}
                                            </button>
                                        </div>
                                        <div className={style.spacer}></div>
                                    </>
                                }
                                {resetPassword &&
                                    <>
                                        <div className={[typo.title, style.title].join(' ')}>{t('login.resetPasswordTitle')}</div>
                                        {!emailSent &&
                                            <div className={[typo.body, style.body].join(' ')}>{t('login.resetPasswordSubtitle')}</div>
                                        }
                                        {emailSent &&
                                            <div className={[typo.body, style.body].join(' ')}>{t('login.waitToResend', { timer: timer })}</div>
                                        }
                                        <div className={style.formContainer}>
                                            <TextInput value={email} type="text" placeholder={t('login.email')} onKeyUp={onEmailChange}></TextInput>
                                            <div className={style.resetPassword}>
                                                <div className={style.error}>
                                                    {error &&
                                                        <span className={typo.caption}>
                                                            {error.message}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <Button onClick={handleResetPassword} disabled={emailSent} fullWidth>{t(emailSent ? 'login.sent' : 'login.send').toUpperCase()}</Button>
                                        <div className={style.spacer}></div>
                                        <Button appearance="text" onClick={handleChangeResetPassword}>{t('login.goBack')}</Button>
                                    </>
                                }
                            </div>
                        </div>
                        <div className={style.columnRight}>
                            <div className={style.innerRight}>
                                <div className={style.redCircle}></div>
                                <div className={[style.rightTitle, typo.title].join(' ')}>{t('login.contentTitle')}</div>
                                <div className={[typo.body, style.rightBody].join(' ')}>{t('login.contentSubtitle')}</div>
                                <div className={style.mockup}></div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </FooterLayout>
    )

}

export default Login
